import { faSearch } from '@fortawesome/pro-light-svg-icons'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { RootState } from '../../../../state'
import { changeQuery } from '../../../../state/search/search.actions'
import Loader from '../../../general/Loader/Loader'
import ObjectPicture from '../../../general/ObjectPicture/ObjectPicture'
import SearchBar from '../SearchBar/SearchBar'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: ${(props) => props.theme.background};
`

const StyledScroller = styled.div`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const StyledResults = styled.div`
  padding: 15px 30px;
`

const StyledResultLink = styled(Link)`
  display: flex;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledPicture = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: grey;
  border-radius: 50%;
  overflow: hidden;
`

const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 0 10px;
  overflow: hidden;
`

const StyledName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
  font-weight: bold;
  color: ${(props) => props.theme.primaryText};
  text-decoration: none;
`

const StyledTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
  text-decoration: none;
  color: ${(props) => props.theme.secondaryText};
`

const PractitionerSearch: React.FC = () => {
  const practitioners = useSelector((state: RootState) => state.search.practitioners.data)
  const pending = useSelector((state: RootState) => state.search.practitioners.status === 'PENDING')
  const query = useSelector((state: RootState) => state.search.terms.query)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { view } = useParams<{ view: string }>()

  return (
    <StyledContainer>
      <Translate>
        {({ translate }) => (
          <SearchBar
            query={query}
            icon={faSearch}
            placeholder={translate('header.searchPlaceholder') as string}
            onChange={(newQuery) => dispatch(changeQuery(newQuery))}
            onClose={() => history.replace(`/search/${view}${location.search}`)}
          />
        )}
      </Translate>
      <StyledScroller>
        <StyledResults>
          {pending ? (
            <Loader />
          ) : (
            <Translate>
              {({ activeLanguage }) =>
                practitioners.map((practitioner) => (
                  <StyledResultLink key={practitioner.id} to={`/recipient/${practitioner.id}`}>
                    <StyledPicture>
                      <ObjectPicture objectId={practitioner.id} objectType={'practitioner'} />
                    </StyledPicture>
                    <StyledDetails>
                      <StyledName>{practitioner.suggestion[activeLanguage.code]}</StyledName>
                      <StyledTitle>{practitioner.additionalInfo[activeLanguage.code]}</StyledTitle>
                    </StyledDetails>
                  </StyledResultLink>
                ))
              }
            </Translate>
          )}
        </StyledResults>
      </StyledScroller>
    </StyledContainer>
  )
}

export default PractitionerSearch
