import React, { useState, useEffect } from 'react'

import { getUserTokenObservable } from '../../../services/native'

interface Props {
  children: JSX.Element
}

export const TokenGate: React.FC<Props> = (props) => {
  const [localToken, setLocalToken] = useState<string>('')

  useEffect(() => {
    const userTokenSubscription = getUserTokenObservable.subscribe((token) => setLocalToken(token))

    return () => {
      userTokenSubscription.unsubscribe()
    }
  }, [])

  return localToken ? props.children : null
}
