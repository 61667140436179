import { parse as parseDate, format, setDate } from 'date-fns'
import { parse as parseQuery } from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { locales } from '../../../locale/dateLocales'
import { RootState } from '../../../state'
import * as actions from '../../../state/recipient/recipient.actions'
import { hasReservationsForDate } from '../../../state/search/search.selectors'
import { AppointmentPrice } from '../../../types'
import { typeInfoMapping } from '../../../utils'
import { formatCurrency } from '../../../utils/formatCurrency'
import Button from '../../general/Button/Button'
import Calendar from '../../general/Calendar/Calendar'

import Appointments from './Appointments/Appointments'
import ConfirmPopup from './ConfirmPopup/ConfirmPopup'
import Details from './Details/Details'
import Header from './Header/Header'
import Location from './Location/Location'
import PersonalInformation from './PersonalInformation/PersonalInformation'
import Reschedule from './Reschedule/Reschedule'
import Services from './Services/Services'
import TypeInfo from './TypeInfo/TypeInfo'
import Types from './Types/Types'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: ${(props) => props.theme.contentBackground};
`

const StyledScroller = styled.div`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const StyledContent = styled.div`
  padding: 20px 0;
`

const StyledTitle = styled.h2`
  margin: 0 0 5px 0;
  padding: 0 20px;
`

const StyledSubtitle = styled.p`
  color: ${(props) => props.theme.secondaryText};
  margin: 0 0 20px 0;
  padding: 0 20px;
`

const StyledCalendar = styled.div`
  padding: 0 5px 25px 5px;
`

const StyledError = styled.div`
  color: ${(props) => props.theme.errorColor};
  text-align: center;
  padding: 15px;
`

const StyledPrice = styled.div`
  color: ${(props) => props.theme.primaryText};
  text-align: center;
  font-weight: bold;
  font-size: 30px;
`

const StyledButton = styled.div`
  padding: 20px;
`

const Recipient: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { practitionerId } = useParams<{ practitionerId?: string }>()
  const { date, service, type } = parseQuery(location.search)
  const dateStore = useSelector((state: RootState) => state.recipient.terms.date)
  const activeMonthStore = useSelector((state: RootState) => state.recipient.terms.activeMonth)
  const appointmentStore = useSelector((state: RootState) => state.recipient.terms.appointmentId)
  const calendarPendingStore = useSelector(
    (state: RootState) => state.recipient.calendar.status === 'PENDING'
  )
  const calendarSlotsStore = useSelector((state: RootState) => state.recipient.calendar.data)
  const reservationStatusStore = useSelector(
    (state: RootState) => state.recipient.reservation.status
  )
  const practitionerStore = useSelector((state: RootState) => state.recipient.terms.practitioner)
  const [activePrice, setActivePrice] = useState<AppointmentPrice | null>(null)
  const reservationPendingStore = useSelector(
    (state: RootState) => state.recipient.reservation.status === 'PENDING'
  )
  const rescheduleStore = useSelector(
    (state: RootState) => state.recipient.reschedule.appointmentId
  )
  const cancelPendingStore = useSelector(
    (state: RootState) => state.recipient.cancel.status === 'PENDING'
  )
  const reservationErrorStore = useSelector((state: RootState) => state.recipient.reservation.error)
  const cancelErrorStore = useSelector((state: RootState) => state.recipient.cancel.error)
  const typeStore = useSelector((state: RootState) => state.recipient.terms.type)
  const serviceStore = useSelector((state: RootState) => state.recipient.terms.service)
  const hasSameServiceBookingToday = useSelector(
    (state: RootState) =>
      serviceStore !== null &&
      dateStore !== null &&
      hasReservationsForDate(state.search, dateStore, serviceStore)
  )
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false)
  const infoMapping = typeInfoMapping.find((mapping) => mapping.id === activePrice?.timeslotTypeId)
  const scrollEl = useRef<HTMLDivElement>(null)

  const [additionalInfo, setAdditionalInfo] = useState('')

  useEffect(() => {
    dispatch(actions.iniateLoad())
  }, [dispatch])

  useEffect(() => {
    if (practitionerId) {
      dispatch(actions.changePractitioner(parseInt(practitionerId, 10)))
    }
    dispatch(actions.changeService(null))
    dispatch(actions.changeType(typeof type !== 'undefined' ? parseInt(type as string, 10) : null))

    const parsedDate =
      typeof date === 'undefined' ? new Date() : parseDate(date as string, 'dd-MM-yyyy', new Date())
    dispatch(actions.changeDate(parsedDate))
    dispatch(actions.changeActiveMonth(setDate(parsedDate, 1)))
  }, [date, type, practitionerId, dispatch])

  useEffect(() => {
    if (reservationStatusStore === 'FULFILLED' && appointmentStore) {
      history.push(`/appointment/${appointmentStore}/confirm`)
    }
  }, [reservationStatusStore, history, appointmentStore])

  useEffect(() => {
    const { reschedule } = parseQuery(location.search)
    if (reschedule) {
      dispatch(actions.changeReschedule(parseInt(reschedule as string, 10)))
    }
  }, [location, dispatch])

  const confirm = (appointmentId: number, rescheduleAppointmentId?: number) => {
    if (practitionerStore !== null && activePrice !== null) {
      if (typeof rescheduleAppointmentId !== 'undefined') {
        dispatch(
          actions.doReschedule.request({
            reservation: {
              practitionerId: practitionerStore,
              timeslotId: appointmentId,
              priceId: activePrice.id,
              bookedReason: additionalInfo,
            },
            rescheduleId: rescheduleAppointmentId,
          })
        )
      } else {
        if (hasSameServiceBookingToday && !confirmVisible) {
          setConfirmVisible(true)
        } else {
          dispatch(
            actions.doReservation.request({
              practitionerId: practitionerStore,
              timeslotId: appointmentId,
              priceId: activePrice.id,
              bookedReason: additionalInfo,
            })
          )
        }
      }
    }
  }

  const phases = [
    <React.Fragment key="phase1">
      <StyledTitle>
        <Translate id="recipient.serviceTitle" />
      </StyledTitle>
      <StyledSubtitle>
        <Translate id="recipient.serviceSubtitle" />
      </StyledSubtitle>
      <Services
        preferredService={typeof service !== 'undefined' ? parseInt(service as string, 10) : null}
      />
    </React.Fragment>,

    serviceStore ? (
      <React.Fragment key="phase2">
        <StyledTitle>
          <Translate id="recipient.typeTitle" />
        </StyledTitle>
        <StyledSubtitle>
          <Translate id="recipient.typeSubtitle" />
        </StyledSubtitle>
        <Types />
      </React.Fragment>
    ) : null,

    serviceStore && typeStore ? (
      <React.Fragment key="phase3">
        <StyledTitle>
          <Translate id="recipient.dateTitle" />
        </StyledTitle>
        <StyledSubtitle>
          <Translate id="recipient.dateSubtitle" />
        </StyledSubtitle>
        <StyledCalendar>
          <Calendar
            activeMonth={activeMonthStore ? activeMonthStore : new Date()}
            activeDate={dateStore ? dateStore : new Date()}
            onSetActiveMonth={(newMonth) => dispatch(actions.changeActiveMonth(newMonth))}
            onSetActiveDate={(newDate) => dispatch(actions.changeDate(newDate))}
            pending={calendarPendingStore}
            slots={calendarSlotsStore}
          />
        </StyledCalendar>
      </React.Fragment>
    ) : null,

    serviceStore && typeStore && dateStore ? (
      <React.Fragment key="phase4">
        <Translate>
          {({ activeLanguage }) =>
            dateStore ? (
              <StyledTitle>
                <Translate
                  id="recipient.appointmentsTitle"
                  data={{ date: format(dateStore, 'P', { locale: locales[activeLanguage?.code] }) }}
                />
              </StyledTitle>
            ) : null
          }
        </Translate>
        <StyledSubtitle>
          <Translate id="recipient.appointmentsSubtitle" />
        </StyledSubtitle>
        <Appointments
          activeService={serviceStore}
          activeType={typeStore}
          activeAppointment={appointmentStore}
          onSetAppointmentAndPrice={(appointment, price) => {
            dispatch(actions.changeAppointment(appointment.timeslotId))
            dispatch(actions.changeLocation(appointment.location.locationId))
            setActivePrice(price)
          }}
        />
      </React.Fragment>
    ) : null,

    appointmentStore && activePrice ? (
      <React.Fragment key="phase5">
        <StyledTitle>
          <Translate id="recipient.contactTitle" />
        </StyledTitle>
        <StyledSubtitle>
          <Translate id="recipient.contactSubtitle" />
        </StyledSubtitle>
        <PersonalInformation
          maxLength={100}
          additionalInfo={additionalInfo}
          onChangeAdditionalInfo={setAdditionalInfo}
        />
        <Details />
        {!!infoMapping && infoMapping.remote ? (
          <>
            <StyledTitle>
              <Translate id={infoMapping.title} />
            </StyledTitle>
            <TypeInfo text={<Translate id={infoMapping.subtitle} />} icon={infoMapping?.icon} />
          </>
        ) : (
          <>
            <StyledTitle>
              <Translate id="recipient.locationTitle" />
            </StyledTitle>
            <Location />
          </>
        )}
        {cancelErrorStore && (
          <StyledError>
            <Translate id="recipient.rescheduleFail" />
          </StyledError>
        )}
        {reservationErrorStore && (
          <StyledError>
            <Translate id="recipient.reservationFail" />
          </StyledError>
        )}
        <StyledPrice>
          {typeof activePrice.price === 'number' && formatCurrency(activePrice.price * 100)}
        </StyledPrice>
        <StyledButton>
          {rescheduleStore ? (
            <Button
              fullWidth
              onClick={() => confirm(appointmentStore, rescheduleStore)}
              label={<Translate id="recipient.rescheduleAppointmentTitle" />}
              pending={reservationPendingStore || cancelPendingStore}
            />
          ) : (
            <Button
              fullWidth
              onClick={() => confirm(appointmentStore)}
              label={<Translate id="recipient.bookAppointmentTitle" />}
              pending={reservationPendingStore}
            />
          )}
        </StyledButton>
      </React.Fragment>
    ) : null,
  ]

  const Confirm = () =>
    appointmentStore ? (
      <ConfirmPopup
        show={confirmVisible}
        onClose={() => setConfirmVisible(false)}
        onConfirm={() => confirm(appointmentStore)}
        pending={reservationPendingStore}
      />
    ) : null

  return (
    <StyledContainer>
      <StyledScroller ref={scrollEl}>
        <Header scroller={scrollEl.current} />
        <StyledContent>{phases}</StyledContent>
      </StyledScroller>
      <Reschedule />
      <Confirm />
    </StyledContainer>
  )
}

export default Recipient
