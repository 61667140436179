import { getType } from 'typesafe-actions'

import {
  HTTPRequestStatus,
  PractitionerDetails,
  Appointment,
  PersonalInformation,
  LocationDetails,
  CalendarSlot,
  PractitionerLanguage,
  PractitionerPatientGroup,
} from '../../types'

import { RecipientAction } from './'
import * as actions from './recipient.actions'

export interface RecipientState {
  terms: {
    date: Date | null
    activeMonth: Date | null
    practitioner: number | null
    appointmentId: number | null
    locationId: number | null
    service: number | null
    type: number | null
  }
  practitionerDetails: {
    data: {
      details: PractitionerDetails
      languages: PractitionerLanguage[]
      patientGroups: PractitionerPatientGroup[]
    } | null
    status: HTTPRequestStatus
    error: Error | null
  }
  practitionerServices: {
    data: number[] | null
    status: HTTPRequestStatus
    error: Error | null
  }
  appointments: {
    data: Appointment[] | null
    status: HTTPRequestStatus
    error: Error | null
  }
  calendar: {
    data: CalendarSlot[]
    status: HTTPRequestStatus
    error: Error | null
  }
  person: {
    data: PersonalInformation | null
    status: HTTPRequestStatus
    error: Error | null
  }
  location: {
    data: LocationDetails | null
    status: HTTPRequestStatus
    error: Error | null
  }
  reservation: {
    status: HTTPRequestStatus
    error: Error | null
  }
  reschedule: {
    appointmentId: number | null
    data: Appointment | null
    status: HTTPRequestStatus
    error: Error | null
  }
  cancel: {
    status: HTTPRequestStatus
    error: Error | null
  }
}

const initialState: RecipientState = {
  terms: {
    date: null,
    activeMonth: null,
    practitioner: null,
    appointmentId: null,
    locationId: null,
    service: null,
    type: null,
  },
  practitionerDetails: {
    data: null,
    status: '',
    error: null,
  },
  practitionerServices: {
    data: null,
    status: '',
    error: null,
  },
  appointments: {
    data: [],
    status: '',
    error: null,
  },
  calendar: {
    data: [],
    status: '',
    error: null,
  },
  person: {
    data: null,
    status: '',
    error: null,
  },
  location: {
    data: null,
    status: '',
    error: null,
  },
  reservation: {
    status: '',
    error: null,
  },
  reschedule: {
    appointmentId: null,
    data: null,
    status: '',
    error: null,
  },
  cancel: {
    status: '',
    error: null,
  },
}

export function reducer(
  state: RecipientState = initialState,
  action: RecipientAction
): RecipientState {
  switch (action.type) {
    case getType(actions.changeDate): {
      return {
        ...state,
        terms: {
          ...state.terms,
          date: action.payload,
          appointmentId: null,
        },
        reservation: {
          ...state.reservation,
          status: '',
        },
      }
    }

    case getType(actions.changeActiveMonth): {
      return {
        ...state,
        terms: {
          ...state.terms,
          activeMonth: action.payload,
        },
      }
    }

    case getType(actions.changePractitioner): {
      return {
        ...state,
        terms: {
          ...state.terms,
          practitioner: action.payload,
          appointmentId: null,
        },
      }
    }

    case getType(actions.changeAppointment): {
      return {
        ...state,
        terms: {
          ...state.terms,
          appointmentId: action.payload,
        },
        reservation: {
          ...state.reservation,
          error: null,
        },
      }
    }

    case getType(actions.changeLocation): {
      return {
        ...state,
        terms: {
          ...state.terms,
          locationId: action.payload,
        },
      }
    }

    case getType(actions.getPractitionerDetails.request): {
      return {
        ...state,
        practitionerDetails: {
          ...state.practitionerDetails,
          status: 'PENDING',
          data: null,
          error: null,
        },
      }
    }

    case getType(actions.getPractitionerDetails.success): {
      return {
        ...state,
        practitionerDetails: {
          ...state.practitionerDetails,
          status: 'FULFILLED',
          data: {
            details: action.payload[0],
            languages: action.payload[1],
            patientGroups: action.payload[2],
          },
          error: null,
        },
      }
    }

    case getType(actions.getPractitionerDetails.failure): {
      return {
        ...state,
        practitionerDetails: {
          ...state.practitionerDetails,
          status: 'REJECTED',
          data: null,
          error: action.payload,
        },
      }
    }

    case getType(actions.getPractitionerServices.request): {
      return {
        ...state,
        practitionerServices: {
          ...state.practitionerServices,
          status: 'PENDING',
          data: null,
          error: null,
        },
      }
    }

    case getType(actions.getPractitionerServices.success): {
      return {
        ...state,
        practitionerServices: {
          ...state.practitionerServices,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.getPractitionerServices.failure): {
      return {
        ...state,
        practitionerServices: {
          ...state.practitionerServices,
          status: 'REJECTED',
          data: null,
          error: action.payload,
        },
      }
    }

    case getType(actions.doSearch.request): {
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: 'PENDING',
          data: null,
          error: null,
        },
      }
    }

    case getType(actions.doSearch.success): {
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.doSearch.failure): {
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: 'REJECTED',
          data: null,
          error: action.payload,
        },
      }
    }

    case getType(actions.doCalendarSearch.request): {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          status: 'PENDING',
          data: [],
          error: null,
        },
      }
    }

    case getType(actions.doCalendarSearch.success): {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.doCalendarSearch.failure): {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          status: 'REJECTED',
          data: [],
          error: action.payload,
        },
      }
    }

    case getType(actions.getPerson.request): {
      return {
        ...state,
        person: {
          ...state.person,
          status: 'PENDING',
          data: null,
          error: null,
        },
      }
    }

    case getType(actions.getPerson.success): {
      return {
        ...state,
        person: {
          ...state.person,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.getPerson.failure): {
      return {
        ...state,
        person: {
          ...state.person,
          status: 'REJECTED',
          data: null,
          error: action.payload,
        },
      }
    }

    case getType(actions.getLocation.request): {
      return {
        ...state,
        location: {
          ...state.location,
          status: 'PENDING',
          data: null,
          error: null,
        },
      }
    }

    case getType(actions.getLocation.success): {
      return {
        ...state,
        location: {
          ...state.location,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.getLocation.failure): {
      return {
        ...state,
        location: {
          ...state.location,
          status: 'REJECTED',
          data: null,
          error: action.payload,
        },
      }
    }

    case getType(actions.doReservation.request): {
      return {
        ...state,
        reservation: {
          ...state.reservation,
          status: 'PENDING',
          error: null,
        },
      }
    }

    case getType(actions.doReservation.success): {
      return {
        ...state,
        reservation: {
          ...state.reservation,
          status: 'FULFILLED',
          error: null,
        },
      }
    }

    case getType(actions.doReservation.failure): {
      return {
        ...state,
        reservation: {
          ...state.reservation,
          status: 'REJECTED',
          error: action.payload,
        },
      }
    }

    case getType(actions.changeReschedule): {
      return {
        ...state,
        reschedule: {
          ...state.reschedule,
          appointmentId: action.payload,
        },
      }
    }

    case getType(actions.getRescheduleAppointment.request): {
      return {
        ...state,
        reschedule: {
          ...state.reschedule,
          data: null,
          status: 'PENDING',
          error: null,
        },
      }
    }

    case getType(actions.getRescheduleAppointment.success): {
      return {
        ...state,
        reschedule: {
          ...state.reschedule,
          data: action.payload,
          status: 'FULFILLED',
          error: null,
        },
      }
    }

    case getType(actions.getRescheduleAppointment.failure): {
      return {
        ...state,
        reschedule: {
          ...state.reschedule,
          status: 'REJECTED',
          error: action.payload,
        },
      }
    }

    case getType(actions.doReschedule.request): {
      return {
        ...state,
        cancel: {
          ...state.cancel,
          status: 'PENDING',
          error: null,
        },
      }
    }

    case getType(actions.doReschedule.success): {
      return {
        ...state,
        cancel: {
          ...state.cancel,
          status: 'FULFILLED',
          error: null,
        },
        reschedule: {
          appointmentId: null,
          data: null,
          status: '',
          error: null,
        },
      }
    }

    case getType(actions.doReschedule.failure): {
      return {
        ...state,
        cancel: {
          ...state.cancel,
          status: 'REJECTED',
          error: action.payload,
        },
      }
    }

    case getType(actions.changeService): {
      return {
        ...state,
        terms: {
          ...state.terms,
          appointmentId: null,
          service: action.payload,
        },
      }
    }

    case getType(actions.changeType): {
      return {
        ...state,
        terms: {
          ...state.terms,
          appointmentId: null,
          type: action.payload,
        },
      }
    }

    default: {
      return state
    }
  }
}
