import { faLocationArrow } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { openMap } from '../../../../services/native'
import { RootState } from '../../../../state'
import Loader from '../../../general/Loader/Loader'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  color: ${(props) => props.theme.selectionColor};
  text-align: left;
`

const StyledIcon = styled.div`
  color: ${(props) => props.theme.selectionColor};
`

const Location: React.FC = () => {
  const locationDetails = useSelector((state: RootState) => state.recipient.location.data)
  const pending = useSelector(
    (state: RootState) => state.recipient.practitionerDetails.status === 'PENDING'
  )

  return pending ? (
    <Loader />
  ) : (
    <Translate>
      {({ activeLanguage }) =>
        locationDetails && (
          <button onClick={() => openMap(locationDetails.latitude, locationDetails.longitude)}>
            <StyledContainer>
              <StyledIcon>
                <FontAwesomeIcon
                  icon={faLocationArrow}
                  style={{
                    width: 23,
                    height: 23,
                    marginRight: 15,
                  }}
                />
              </StyledIcon>
              <div>
                <div>{locationDetails.locationName[activeLanguage.code]}</div>
                <div>{locationDetails.locationAddress[activeLanguage.code]}</div>
                <div>{locationDetails.locationCity[activeLanguage.code]}</div>
              </div>
            </StyledContainer>
          </button>
        )
      }
    </Translate>
  )
}

export default Location
