import { Epic, combineEpics } from 'redux-observable'
import { of, from, EMPTY, zip } from 'rxjs'
import {
  filter,
  switchMap,
  map,
  catchError,
  mergeMap,
  throttleTime,
  debounceTime,
  tap,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { RootAction, RootState } from '../'
import * as api from '../../services/api'
import { Firebase } from '../../utils'
import { getUserId } from '../general/general.selectors'
import { getReservations } from '../search/search.actions'

import * as actions from './recipient.actions'

type RecipientEpic = Epic<RootAction, RootAction, RootState>

const searchTermActions = [
  actions.changeDate,
  actions.changePractitioner,
  actions.changeService,
  actions.changeType,
]

const AppropriateSearchFilter = (state: RootState) =>
  state.recipient.terms.date !== null &&
  state.recipient.terms.practitioner !== null &&
  state.recipient.terms.type !== null &&
  state.recipient.terms.service !== null

const initialLoad: RecipientEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.iniateLoad)),
    tap(() => Firebase.trackEvent('screen_view', { screen_name: 'recipient' })),
    switchMap(() => {
      if (state$.value.search.reservations.status === '') {
        // Reservations not fetched yet
        return of(getReservations.request(false))
      }
      return EMPTY
    })
  )

const updateSerch: RecipientEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(searchTermActions)),
    filter(() => AppropriateSearchFilter(state$.value)),
    mergeMap((_) => of(actions.doSearch.request(state$.value.recipient.terms)))
  )

const getPractitionerServicesTrigger: RecipientEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.changePractitioner)),
    mergeMap((action) => of(actions.getPractitionerServices.request(action.payload)))
  )

const getPractitionerServices: RecipientEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.getPractitionerServices.request)),
    switchMap((action) =>
      from(api.getPractitionerServices(action.payload)).pipe(
        map(actions.getPractitionerServices.success),
        catchError((error) => of(actions.getPractitionerServices.failure(error)))
      )
    )
  )

const getPractitionerDetails: RecipientEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getPractitionerDetails.request)),
    switchMap((action) =>
      zip(
        from(api.getPractitionerDetails(getUserId(state$.value.general), action.payload)),
        from(api.getPractitionerLanguages(action.payload)),
        from(api.getPractitionerPatientGroups(action.payload))
      ).pipe(
        map(actions.getPractitionerDetails.success),
        catchError((error) => of(actions.getPractitionerDetails.failure(error)))
      )
    )
  )

const doSearch: RecipientEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.doSearch.request)),
    debounceTime(100),
    switchMap((action) =>
      from(api.search(action.payload)).pipe(
        map(actions.doSearch.success),
        catchError((error) => of(actions.doSearch.failure(error)))
      )
    )
  )

const updateCalendar: RecipientEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([...searchTermActions, actions.changeActiveMonth])),
    filter(() => AppropriateSearchFilter(state$.value)),
    mergeMap(() =>
      of(
        actions.doCalendarSearch.request({
          ...state$.value.recipient.terms,
          date: state$.value.recipient.terms.activeMonth,
        })
      )
    )
  )

const doCalendarSearch: RecipientEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.doCalendarSearch.request)),
    debounceTime(100),
    switchMap((action) =>
      from(api.calendarSearch(action.payload)).pipe(
        map(actions.doCalendarSearch.success),
        catchError((error) => of(actions.doCalendarSearch.failure(error)))
      )
    )
  )

const getPerson: RecipientEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getPerson.request)),
    switchMap(() =>
      from(api.getPerson(getUserId(state$.value.general))).pipe(
        map(actions.getPerson.success),
        catchError((error) => of(actions.getPerson.failure(error)))
      )
    )
  )

const getLocation: RecipientEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.changeLocation)),
    switchMap((action) =>
      from(api.getPractitionerLocation(getUserId(state$.value.general), action.payload)).pipe(
        map(actions.getLocation.success),
        catchError((error) => of(actions.getLocation.failure(error)))
      )
    )
  )

const doReservation: RecipientEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.doReservation.request)),
    throttleTime(1000),
    switchMap((action) =>
      from(api.reserve(getUserId(state$.value.general), action.payload)).pipe(
        tap(() => Firebase.trackEvent('purchase', { transaction_id: action.payload.timeslotId })),
        map(actions.doReservation.success),
        catchError((error) => of(actions.doReservation.failure(error)))
      )
    )
  )

const getRescheduleAppointmentTrigger: RecipientEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.changeReschedule)),
    filter((action) => action.payload !== null),
    mergeMap((action) => of(actions.getRescheduleAppointment.request(action.payload as number)))
  )

const getRescheduleAppointment: RecipientEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getRescheduleAppointment.request)),
    switchMap((action) =>
      from(api.getAppointment(getUserId(state$.value.general), action.payload)).pipe(
        map(actions.getRescheduleAppointment.success),
        catchError((error) => of(actions.getRescheduleAppointment.failure(error)))
      )
    )
  )

const doReschedule: RecipientEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.doReschedule.request)),
    throttleTime(1000),
    switchMap((action) =>
      from(api.cancel(getUserId(state$.value.general), action.payload.rescheduleId)).pipe(
        tap(() => Firebase.trackEvent('refund', { transaction_id: action.payload.rescheduleId })),
        map(() => actions.doReschedule.success(action.payload.reservation)),
        catchError((error) => of(actions.doReschedule.failure(error)))
      )
    )
  )

const doRescheduleTrigger: RecipientEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.doReschedule.success)),
    mergeMap((action) => of(actions.doReservation.request(action.payload)))
  )

export const epics = combineEpics(
  initialLoad,
  getPractitionerDetails,
  getPractitionerServicesTrigger,
  getPractitionerServices,
  doSearch,
  doCalendarSearch,
  updateSerch,
  updateCalendar,
  getPerson,
  getLocation,
  doReservation,
  getRescheduleAppointmentTrigger,
  getRescheduleAppointment,
  doReschedule,
  doRescheduleTrigger
)
