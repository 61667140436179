import {
  faAngleRight,
  faStethoscope,
  faWalking,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import { format, parseJSON } from 'date-fns'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { Link } from 'react-router-dom'

import {
  I18n,
  Appointment,
  GroupedAppointmentLocation,
  AppointmentType,
  TypeInfoMapping,
  ObjectType,
  ServicePriceType,
} from '../../../../../types'
import { typeInfoMapping } from '../../../../../utils'
import { formatCurrency, formatCurrencyRange } from '../../../../../utils/formatCurrency'
import ObjectPicture from '../../../../general/ObjectPicture/ObjectPicture'

import Style from './AppointmentRow.module.css'

interface Props {
  name: I18n
  medicalService: I18n
  location: GroupedAppointmentLocation[]
  objectId: number
  objectType: ObjectType
  times: Appointment[]
  price: ServicePriceType[] | null
  link: string
  selectedType: number | null
  types: AppointmentType[]
}

const maxDisplayedLocations = 3
const maxDistance = 10000

interface AppointmentsTypeInfoMapping {
  typeMapping: TypeInfoMapping
  appointments: Appointment[]
}

const getAppointmentsByType = (
  appointments: Appointment[],
  selectedType: number | null
): AppointmentsTypeInfoMapping[] =>
  typeInfoMapping
    .filter((typeMapping) => selectedType === null || typeMapping.id === selectedType)
    .map((typeMapping) => ({
      typeMapping,
      appointments: appointments.filter((appointment) =>
        appointment.prices.some((price) => price.timeslotTypeId === typeMapping.id)
      ),
    }))
    .filter((appointmentsByType) => appointmentsByType.appointments.length)

const readableAppointments = (
  appointmentsByType: AppointmentsTypeInfoMapping[],
  types: AppointmentType[]
) => {
  // If only single appointment types are present, display its name in the title
  const singleAppointmentTypeName =
    appointmentsByType.length === 1 &&
    types.find((type) => type.timeslotTypeId === appointmentsByType[0].typeMapping.id)

  return (
    <Translate>
      {({ activeLanguage }) => (
        <>
          <div className={Style.available}>
            <Translate
              id="appointments.available"
              data={{
                appointmentType: singleAppointmentTypeName
                  ? singleAppointmentTypeName.timeslotTypeName[activeLanguage.code]
                  : '',
              }}
            />
          </div>
          {appointmentsByType.map((appointmentsAndType) => (
            <div key={appointmentsAndType.typeMapping.id} className={Style.times}>
              {appointmentsRange(
                appointmentsAndType.typeMapping.icon,
                appointmentsAndType.appointments
              )}
            </div>
          ))}
        </>
      )}
    </Translate>
  )
}

const appointmentsRange = (icon: IconDefinition, appointments: Appointment[]) => {
  const firstAppointment = appointments[0]
  const lastAppointment = appointments[appointments.length - 1]

  return (
    <>
      <div className={Style.timeIcon}>
        <FontAwesomeIcon icon={icon} color="#7f7f7f" size="1x" />
      </div>
      <div className={Style.time}>{format(parseJSON(firstAppointment.start), 'HH:mm')}</div>
      {appointments.length > 1 && (
        <>
          <div className={Style.timeDivider}>–</div>
          <div className={Style.time}>{format(parseJSON(lastAppointment.start), 'HH:mm')}</div>
        </>
      )}
    </>
  )
}

const readableLocations = (locations: GroupedAppointmentLocation[]) => (
  <div className={Style.location}>
    <Translate>
      {({ activeLanguage }) =>
        locations.slice(0, maxDisplayedLocations).map((location, index) => (
          <div key={`location-${index}`}>
            <FontAwesomeIcon
              icon={faWalking}
              color="#7f7f7f"
              size="1x"
              style={{
                marginRight: 5,
              }}
            />
            <Translate
              id="appointments.locationString"
              data={{
                distance: `<strong class="${cn({
                  [Style.locationFar]: isDistanceFar(location.distance),
                })}">${readableDistance(location.distance)}</strong>`,
                location: `<strong>${location.name[activeLanguage.code]}</strong>`,
              }}
            />
          </div>
        ))
      }
    </Translate>
  </div>
)

const readableDistance = (meters: number) => {
  const kilometers = meters / 1000
  if (kilometers < 1) {
    return `${Math.ceil(meters / 100) * 100} m`
  } else {
    return `${Math.round(kilometers * 100) / 100} km`
  }
}

const readablePrice = (prices: ServicePriceType[] | null) => {
  if (prices !== null) {
    const numericPrices = prices.map((price) => (typeof price === 'number' ? price : 0))
    return numericPrices.length > 1
      ? formatCurrencyRange(Math.min(...numericPrices) * 100, Math.max(...numericPrices) * 100)
      : formatCurrency(numericPrices[0] * 100)
  } else {
    return null
  }
}

const isDistanceFar = (meters: number) => meters > maxDistance

const AppointmentRow: React.FC<Props> = (props) => {
  const appointmentsByType = getAppointmentsByType(props.times, props.selectedType)
  // Only show locations if on-premise appointments are present
  const shouldDisplayLocation = appointmentsByType.some(
    (typeMapping) => !typeMapping.typeMapping.remote
  )

  return (
    <Link to={props.link} className={Style.link}>
      <Translate>
        {({ activeLanguage }) => (
          <div className={Style.container}>
            <div className={Style.picture}>
              <ObjectPicture objectId={props.objectId} objectType={props.objectType} />
            </div>
            <div className={Style.details}>
              <div className={Style.name}>{props.name[activeLanguage.code]}</div>
              <div className={Style.title}>
                <FontAwesomeIcon
                  icon={faStethoscope}
                  color="#7f7f7f"
                  size="1x"
                  style={{
                    marginRight: 5,
                  }}
                />
                {props.medicalService[activeLanguage.code]}
              </div>
              {shouldDisplayLocation && readableLocations(props.location)}
              {readableAppointments(appointmentsByType, props.types)}
            </div>
            <div className={Style.price}>{readablePrice(props.price)}</div>
            <div className={Style.arrow}>
              <FontAwesomeIcon
                icon={faAngleRight}
                color="#d3d3d3"
                style={{
                  width: 29,
                  height: 29,
                }}
              />
            </div>
          </div>
        )}
      </Translate>
    </Link>
  )
}

export default AppointmentRow
