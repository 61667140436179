import { RouterState, RouterAction, connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers, Reducer, CombinedState, AnyAction } from 'redux'
import { combineEpics } from 'redux-observable'

import * as confirmed from './confirmed'
import { ConfirmedState } from './confirmed/confirmed.reducer'
import * as general from './general'
import { GeneralState } from './general/general.reducer'
import * as recipient from './recipient'
import { RecipientState } from './recipient/recipient.reducer'
import * as search from './search'
import { SearchState } from './search/search.reducer'

export { search, recipient }

export type RootAction =
  | general.GeneralAction
  | search.SearchAction
  | recipient.RecipientAction
  | confirmed.ConfirmedAction
  | RouterAction

export interface RootState {
  router: RouterState
  general: GeneralState
  search: SearchState
  recipient: RecipientState
  confirmed: ConfirmedState
}

export const rootEpic = combineEpics(general.epics, search.epics, recipient.epics, confirmed.epics)

export const createRootReducer = (history: History): Reducer<CombinedState<RootState>, AnyAction> =>
  combineReducers<RootState>({
    router: connectRouter(history),
    general: general.reducer,
    search: search.reducer,
    recipient: recipient.reducer,
    confirmed: confirmed.reducer,
  })
