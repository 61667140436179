import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import React from 'react'

import Button from '../Button/Button'

import Style from './Popup.module.css'

interface Props {
  className?: string
  title: string | JSX.Element
  onClose?: () => void
  confirmLabel?: string | JSX.Element
  cancelLabel?: string | JSX.Element
  confirmAction?: () => void
  cancelAction?: () => void
  confirmPending?: boolean
  cancelPending?: boolean
}

const Popup: React.FC<Props> = (props) => {
  return (
    <div className={cn(Style.background, props.className)}>
      <div onClick={(event) => event.preventDefault()} className={Style.container}>
        <div className={Style.header}>
          <h2 className={Style.title}>{props.title}</h2>
          {props.onClose && (
            <button onClick={() => props.onClose && props.onClose()} className={Style.close}>
              <FontAwesomeIcon icon={faTimes} size="2x" color="#d3d3d3" />
            </button>
          )}
        </div>
        <div className={Style.content}>{props.children}</div>
        {((props.confirmLabel && props.confirmAction) ||
          (props.cancelLabel && props.cancelAction)) && (
          <div className={Style.actions}>
            {props.cancelLabel && props.cancelAction && (
              <Button
                fullWidth
                className={Style.button}
                onClick={props.cancelAction}
                label={props.cancelLabel}
                theme="transparent"
                pending={props.cancelPending}
              />
            )}
            {props.confirmLabel && props.confirmAction && (
              <Button
                fullWidth
                className={Style.button}
                label={props.confirmLabel}
                onClick={props.confirmAction}
                theme="normal"
                pending={props.confirmPending}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Popup
