import { Config } from './types'

const configProp = (v: { config: Config }): Config => v.config

export function loadConfig(): Promise<Config> {
  switch (process.env.REACT_APP_CUSTOMER) {
    case 'hhg':
      return import('./hhg').then(configProp)
    case 'hakeem':
      return import('./hakeem').then(configProp)
    default:
      return import('./demo').then(configProp)
  }
}

// default config while loading
let config: Config = {
  currency: {
    unit: '€',
    position: 'after',
    numberLocale: 'en-US',
  },
}

export function getConfig() {
  // return config, either default or loaded
  return config
}

export function setConfig(newConfig: Config) {
  config = newConfig
}
