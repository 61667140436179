import { getType } from 'typesafe-actions'

import { HTTPRequestStatus, ObjectDetails, Reservation, LocationDetails } from '../../types'

import { ConfirmedAction } from './'
import * as actions from './confirmed.actions'

export interface ConfirmedState {
  appointment: {
    data: Reservation | null
    status: HTTPRequestStatus
    error: Error | null
  }
  objectDetails: {
    data: ObjectDetails | null
    status: HTTPRequestStatus
    error: Error | null
  }
  location: {
    data: LocationDetails | null
    status: HTTPRequestStatus
    error: Error | null
  }
  cancel: {
    status: HTTPRequestStatus
    error: Error | null
  }
  person: {
    email: string | null
    status: HTTPRequestStatus
    error: Error | null
  }
}

const initialState: ConfirmedState = {
  appointment: {
    data: null,
    status: '',
    error: null,
  },
  objectDetails: {
    data: null,
    status: '',
    error: null,
  },
  location: {
    data: null,
    status: '',
    error: null,
  },
  cancel: {
    status: '',
    error: null,
  },
  person: {
    email: null,
    status: '',
    error: null,
  },
}

export function reducer(
  state: ConfirmedState = initialState,
  action: ConfirmedAction
): ConfirmedState {
  switch (action.type) {
    case getType(actions.getAppointment.request): {
      return {
        ...state,
        appointment: {
          ...state.appointment,
          status: 'PENDING',
          data: null,
          error: null,
        },
      }
    }

    case getType(actions.getAppointment.success): {
      return {
        ...state,
        appointment: {
          ...state.appointment,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.getAppointment.failure): {
      return {
        ...state,
        appointment: {
          ...state.appointment,
          status: 'REJECTED',
          data: null,
          error: action.payload,
        },
      }
    }

    case getType(actions.getObjectDetails.request): {
      return {
        ...state,
        objectDetails: {
          ...state.objectDetails,
          status: 'PENDING',
          data: null,
          error: null,
        },
      }
    }

    case getType(actions.getObjectDetails.success): {
      return {
        ...state,
        objectDetails: {
          ...state.objectDetails,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.getObjectDetails.failure): {
      return {
        ...state,
        objectDetails: {
          ...state.objectDetails,
          status: 'REJECTED',
          data: null,
          error: action.payload,
        },
      }
    }

    case getType(actions.getLocation.request): {
      return {
        ...state,
        location: {
          ...state.location,
          status: 'PENDING',
          data: null,
          error: null,
        },
      }
    }

    case getType(actions.getLocation.success): {
      return {
        ...state,
        location: {
          ...state.location,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.getLocation.failure): {
      return {
        ...state,
        location: {
          ...state.location,
          status: 'REJECTED',
          data: null,
          error: action.payload,
        },
      }
    }

    case getType(actions.cancelAppointment.request): {
      return {
        ...state,
        cancel: {
          ...state.cancel,
          status: 'PENDING',
          error: null,
        },
      }
    }

    case getType(actions.cancelAppointment.success): {
      return {
        ...state,
        cancel: {
          ...state.cancel,
          status: 'FULFILLED',
          error: null,
        },
      }
    }

    case getType(actions.cancelAppointment.failure): {
      return {
        ...state,
        cancel: {
          ...state.cancel,
          status: 'REJECTED',
          error: action.payload,
        },
      }
    }

    case getType(actions.resetState): {
      return initialState
    }

    case getType(actions.getPerson.request): {
      return {
        ...state,
        person: {
          ...state.person,
          status: 'PENDING',
          email: null,
          error: null,
        },
      }
    }

    case getType(actions.getPerson.success): {
      return {
        ...state,
        person: {
          ...state.person,
          status: 'FULFILLED',
          email: action.payload.email,
          error: null,
        },
      }
    }

    case getType(actions.getPerson.failure): {
      return {
        ...state,
        person: {
          ...state.person,
          status: 'REJECTED',
          email: null,
          error: action.payload,
        },
      }
    }

    default: {
      return state
    }
  }
}
