import React from 'react'
import { Translate } from 'react-localize-redux'
import { useSelector } from 'react-redux'

import { RootState } from '../../../../state'
import Accordion from '../../../general/Accordion/Accordion'
import Loader from '../../../general/Loader/Loader'

import Style from './Details.module.css'

const Details: React.FC = () => {
  const practitionerDetails = useSelector(
    (state: RootState) => state.recipient.practitionerDetails.data
  )
  const locationDetails = useSelector((state: RootState) => state.recipient.location.data)
  const pending = useSelector(
    (state: RootState) =>
      state.recipient.practitionerDetails.status === 'PENDING' ||
      state.recipient.location.status === 'PENDING'
  )

  return pending ? (
    <Loader />
  ) : (
    <Translate>
      {({ activeLanguage }) => (
        <div className={Style.container}>
          <Accordion
            className={Style.accordion}
            title={<Translate id="recipient.generalInstructionsTitle" />}
          >
            {locationDetails?.description[activeLanguage.code]}
          </Accordion>
          <Accordion
            className={Style.accordion}
            title={<Translate id="recipient.paymentMethodsTitle" />}
          >
            {practitionerDetails?.details.paymentTerms[activeLanguage.code]}
          </Accordion>
          <Accordion
            className={Style.accordion}
            title={<Translate id="recipient.termsOfCancellationTitle" />}
          >
            {practitionerDetails?.details.cancellationPolicy[activeLanguage.code]}
          </Accordion>
        </div>
      )}
    </Translate>
  )
}

export default Details
