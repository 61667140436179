import React, { useContext, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css, ThemeContext } from 'styled-components'

import { RootState } from '../../../../state'
import { changeService } from '../../../../state/recipient/recipient.actions'
import Loader from '../../../general/Loader/Loader'

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 0 20px;
`

const ActiveMixin = css`
  background: ${(props) => props.theme.selectionColor};
  border-color: ${(props) => props.theme.selectionColor};
  color: ${(props) => props.theme.invertText};
`

const StyledService = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  flex: 0 1;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid ${(props) => props.theme.borderColor};
  border-radius: 4px;
  color: ${(props) => props.theme.selectionColor};
  text-align: center;
  transition: all 150ms ease-out;

  ${(props) => props.active && ActiveMixin}
`

interface Props {
  preferredService: number | null
}

const Services: React.FC<Props> = ({ preferredService }) => {
  const dispatch = useDispatch()
  const servicesStore = useSelector((state: RootState) => state.general.services.data)
  const activeServiceStore = useSelector((state: RootState) => state.recipient.terms.service)
  const practitionerServicesPending = useSelector(
    (state: RootState) => state.recipient.practitionerServices.status === 'PENDING'
  )
  const practitionerServicesStore = useSelector(
    (state: RootState) => state.recipient.practitionerServices.data
  )
  const filteredServices = servicesStore.filter((service) =>
    practitionerServicesStore?.some(
      (practitionerService) => service.medicalServiceId === practitionerService
    )
  )
  const theme = useContext(ThemeContext)

  // Check for url service feasibility and select it if applicable
  useEffect(() => {
    if (
      preferredService !== null &&
      activeServiceStore === null &&
      practitionerServicesStore !== null &&
      practitionerServicesStore.some(
        (practitionerService) => practitionerService === preferredService
      )
    ) {
      dispatch(changeService(preferredService))
    }
  }, [activeServiceStore, preferredService, practitionerServicesStore, dispatch])

  return (
    <StyledContainer>
      {practitionerServicesPending ? (
        <Loader color={theme.selectionColor} />
      ) : (
        <Translate>
          {({ activeLanguage }) =>
            filteredServices.map((service) => (
              <StyledService
                key={service.medicalServiceId}
                active={service.medicalServiceId === activeServiceStore}
                onClick={() => dispatch(changeService(service.medicalServiceId))}
              >
                {service.medicalServiceName[activeLanguage.code]}
              </StyledService>
            ))
          }
        </Translate>
      )}
    </StyledContainer>
  )
}

export default Services
