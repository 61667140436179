import { faAngleRight, faStethoscope } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, parseJSON } from 'date-fns'
import React from 'react'
import { Translate, Language } from 'react-localize-redux'
import { Link } from 'react-router-dom'

import { locales } from '../../../../../locale/dateLocales'
import { Reservation } from '../../../../../types'
import { getReservedAppointmentInfo } from '../../../../../utils'
import ObjectPicture from '../../../../general/ObjectPicture/ObjectPicture'

import Style from './ReservationRow.module.css'

const getTimeObject = (date: Date, activeLanguage: Language) => ({
  day: format(date, 'EEEE', { locale: locales[activeLanguage.code] }),
  date: `${format(date, 'P', { locale: locales[activeLanguage.code] })}`,
  time: `${format(date, 'p', { locale: locales[activeLanguage.code] })}`,
})

interface Props {
  reservation: Reservation
}

const ReservationRow: React.FC<Props> = ({ reservation }) => {
  const typeMap = getReservedAppointmentInfo(reservation)
  if (!typeMap) {
    return null
  }

  return (
    <Link to={`/appointment/${reservation.timeslotId}`} className={Style.link}>
      <Translate>
        {({ activeLanguage, translate }) => {
          const subtitle = typeMap.remote
            ? translate(typeMap.subtitle)
            : reservation.location.locationName[activeLanguage.code]

          return (
            <div className={Style.container}>
              <div className={Style.left}>
                <div className={Style.appointmentType}>{translate(typeMap.title)}</div>
                <div className={Style.time}>
                  {translate(
                    'reservations.time',
                    getTimeObject(parseJSON(reservation.start), activeLanguage)
                  )}
                </div>
                <div className={Style.detailsContainer}>
                  <div className={Style.picture}>
                    <ObjectPicture
                      objectId={reservation.objectId}
                      objectType={reservation.objectType}
                    />
                  </div>
                  <div className={Style.details}>
                    <div className={Style.name}>
                      {reservation.objectDisplayName[activeLanguage.code]}
                    </div>
                    {typeMap.id !== null && !reservation.isExternalObject && (
                      <div className={Style.title}>
                        <FontAwesomeIcon icon={faStethoscope} />
                        <span>
                          {reservation.objectPrimaryMedicalServiceName[activeLanguage.code]}
                        </span>
                      </div>
                    )}
                    {!!subtitle && (
                      <div className={Style.subTitle}>
                        <FontAwesomeIcon icon={typeMap.icon} />
                        <span>{subtitle}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={Style.arrow}>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  color="#d3d3d3"
                  style={{
                    width: 29,
                    height: 29,
                  }}
                />
              </div>
            </div>
          )
        }}
      </Translate>
    </Link>
  )
}

export default ReservationRow
