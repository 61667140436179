import { faCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'

import { RootState } from '../../../../state'
import { changeType } from '../../../../state/recipient/recipient.actions'
import { typeInfoMapping } from '../../../../utils'

const StyledContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding: 0 20px;
`

const ActiveMixin = css`
  background: ${(props) => props.theme.selectionColor};
  border-color: ${(props) => props.theme.selectionColor};
  color: ${(props) => props.theme.invertText};
`

const StyledType = styled.div<{ active?: boolean }>`
  flex: 1;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid ${(props) => props.theme.borderColor};
  border-radius: 4px;
  color: ${(props) => props.theme.selectionColor};
  text-align: center;
  transition: all 150ms ease-out;

  ${(props) => props.active && ActiveMixin}
`

const Types = () => {
  const dispatch = useDispatch()
  const types = useSelector((state: RootState) => state.general.types.data)
  const typeStore = useSelector((state: RootState) => state.recipient.terms.type)

  const getIconForType = (typeId: number) => {
    const iconMapping = typeInfoMapping.find((type) => type.id === typeId)
    return iconMapping ? iconMapping.icon : faCircle
  }

  return (
    <StyledContainer>
      <Translate>
        {({ activeLanguage }) =>
          types.map((type) => (
            <StyledType
              key={type.timeslotTypeId}
              active={type.timeslotTypeId === typeStore}
              onClick={() => dispatch(changeType(type.timeslotTypeId))}
            >
              <div>
                <FontAwesomeIcon
                  icon={getIconForType(type.timeslotTypeId)}
                  style={{
                    width: 29,
                    height: 29,
                  }}
                />
              </div>
              {type.timeslotTypeName[activeLanguage.code]}
            </StyledType>
          ))
        }
      </Translate>
    </StyledContainer>
  )
}

export default Types
