import { getConfig } from '../config'

const addUnit = (value: string): string => {
  const { currency } = getConfig()
  switch (currency.position) {
    case 'before':
      // before -> no space
      return `${currency.unit}${value}`
    case 'after':
      return `${value} ${currency.unit}`
  }
}

const centToCurrency = (cents?: number | null) => {
  if (typeof cents !== 'number') return ''
  const { currency } = getConfig()
  const unit = cents / 100
  const digits = unit % 1 === 0 ? 0 : 2
  return unit.toLocaleString(currency.numberLocale, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
}

// 150, 300 -> '1.50-3 €' | undefined, 295 -> '2.95 €' | 100, 100 -> '100 €'
export const formatCurrencyRange = (minCents?: number | null, maxCents?: number | null): string => {
  const min = centToCurrency(minCents)
  const max = centToCurrency(maxCents)
  return addUnit(min === max ? `${max}` : `${min}${min && max && '–'}${max}`)
}

// 150 -> '1.50 €' | 100 -> '1 €'
export const formatCurrency = (cents?: number | null): string => addUnit(centToCurrency(cents))
