import { faTimesCircle, IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef } from 'react'
import { Translate } from 'react-localize-redux'
import styled from 'styled-components'

const StyledContainer = styled.div`
  background: ${(props) => props.theme.accentColor};
  padding-top: 14px;
  padding-top: max(14px, env(safe-area-inset-top));
`

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`

const StyledInputContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0 8px;
  align-items: center;
  justify-content: center;
  height: 34px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 17px;
  transition: width 2s;
`

const StyledInput = styled.input`
  flex: 1;
  margin: 0 5px;
  background: transparent;
  border: none;
  outline: none;
  color: ${(props) => props.theme.invertText};
  font-size: 15px;

  &::placeholder {
    color: ${(props) => props.theme.invertText};
  }
`

const StyledButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: ${(props) => props.theme.invertText};
  -webkit-tap-highlight-color: transparent;
`

const StyledCancelButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: ${(props) => props.theme.invertText};
  transition: width 0.5s;
  width: 50px;
  -webkit-tap-highlight-color: transparent;
`

const StyledIcon = styled.div`
  display: flex;
  color: ${(props) => props.theme.invertText};
`

interface Props {
  query: string | null
  icon?: IconDefinition
  placeholder: string
  onChange: (query: string | null) => void
  onClose: () => void
}

const SearchBar: React.FC<Props> = (props) => {
  const inputEl = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputEl?.current?.focus()
  }, [])

  const clearQuery = () => {
    props.onChange(null)
    inputEl?.current?.focus()
  }

  return (
    <StyledContainer>
      <StyledRow>
        <StyledInputContainer>
          {props.icon && (
            <StyledIcon>
              <FontAwesomeIcon
                icon={props.icon}
                style={{
                  width: 19,
                  height: 19,
                }}
              />
            </StyledIcon>
          )}
          <StyledInput
            ref={inputEl}
            value={props.query || ''}
            onChange={(event) => props.onChange(event.target.value)}
            placeholder={props.placeholder}
          />
          <StyledButton>
            {props.query && props.query.length > 0 && (
              <StyledIcon>
                <FontAwesomeIcon
                  onClick={clearQuery}
                  icon={faTimesCircle}
                  style={{
                    width: 16,
                    height: 16,
                  }}
                />
              </StyledIcon>
            )}
          </StyledButton>
        </StyledInputContainer>
        <StyledCancelButton onClick={(_) => props.onClose()}>
          <Translate id="general.cancel" />
        </StyledCancelButton>
      </StyledRow>
    </StyledContainer>
  )
}

export default SearchBar
