import { IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { darken } from 'polished'
import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  color: ${(props) => props.theme.primaryText};
  text-align: left;
`

const StyledIcon = styled.div`
  color: ${(props) => darken(0.1, props.theme.accentColor)};
`

interface Props {
  text: JSX.Element
  icon: IconDefinition
}

const TypeInfo: React.FC<Props> = (props) => {
  return (
    <StyledContainer>
      <StyledIcon>
        <FontAwesomeIcon
          icon={props.icon}
          style={{
            width: 23,
            height: 23,
            marginRight: 15,
          }}
        />
      </StyledIcon>
      <div>{props.text}</div>
    </StyledContainer>
  )
}

export default TypeInfo
