import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  format,
  setDay,
  setDate,
  getISODay,
  getDaysInMonth,
  addMonths,
  isSameDay,
  parseJSON,
  isThisMonth,
  isBefore,
} from 'date-fns'
import React from 'react'
import { Translate } from 'react-localize-redux'
import styled, { css } from 'styled-components'

import { locales } from '../../../locale/dateLocales'
import { CalendarSlot } from '../../../types'

const StyledContainer = styled.div`
  padding: 15px;
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`

const StyledMonth = styled.div`
  flex: 1;
  color: ${(props) => props.theme.selectionColor};
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
`

const StyledYear = styled.div`
  display: block;
  font-size: 13px;
  color: ${(props) => props.theme.secondaryText};
`

const StyledDays = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledDayBase = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledDay = styled(StyledDayBase)`
  width: 14%;
  height: 30px;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.primaryText};
  font-size: 15px;
  font-weight: bold;
  transition: all 150ms ease-out;
`

const StyledWeekday = styled(StyledDayBase)`
  width: 14%;
  height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.primaryText};
  font-size: 13px;
  font-weight: 600;
`

const pendingMixin = css`
  animation-duration: 1s;
  animation-name: pendingAnimation;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: backwards;

  @keyframes pendingAnimation {
    from {
      background: rgba(0, 0, 0, 0.1);
    }

    to {
      background: rgba(0, 0, 0, 0);
    }
  }
`

const availableMixin = css`
  color: ${(props) => props.theme.selectionColor};
  border: 2px solid ${(props) => props.theme.borderColor};
`

const selectedMixin = css`
  color: ${(props) => props.theme.invertText};
  background: ${(props) => props.theme.selectionColor};
  border: 2px solid ${(props) => props.theme.selectionColor};
`

const pastMixin = css`
  color: ${(props) => props.theme.secondaryText};
  background: transparent;
  border: none;
  animation: none;
`

const StyledDayNumber = styled.span<{
  pending?: boolean
  available?: boolean
  selected?: boolean
  past?: boolean
}>`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  margin: 0 2.5px;

  ${(props) => props.pending && pendingMixin}
  ${(props) => props.available && availableMixin}
  ${(props) => props.selected && selectedMixin}
  ${(props) => props.past && pastMixin}
`

const StyledArrowButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  color: ${(props) => (props.disabled ? props.theme.secondaryText : props.theme.selectionColor)};
`

interface Props {
  activeMonth: Date
  activeDate: Date
  onSetActiveMonth: (month: Date) => void
  onSetActiveDate: (date: Date) => void
  pending: boolean
  slots: CalendarSlot[]
  pastSelectable?: boolean
}

const dayIsPast = (date: Date, pastSelectable?: boolean) => {
  const today = new Date()
  return !pastSelectable && isBefore(date, today) && !isSameDay(date, today)
}

const backIsDisabled = (date: Date, pastSelectable?: boolean) =>
  !pastSelectable && isThisMonth(date)

const Calendar: React.FC<Props> = (props) => {
  const hasAvailableSlots = (date: Date, slots: CalendarSlot[]): boolean =>
    slots.some((slot) => isSameDay(date, parseJSON(slot.date)) && slot.hasFreeTimes)

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledArrowButton
          disabled={backIsDisabled(props.activeMonth, props.pastSelectable)}
          onClick={(_) =>
            !backIsDisabled(props.activeMonth, props.pastSelectable) &&
            props.onSetActiveMonth(addMonths(props.activeMonth, -1))
          }
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            style={{
              width: 29,
              height: 29,
            }}
          />
        </StyledArrowButton>
        <Translate>
          {({ activeLanguage }) => (
            <StyledMonth>
              {format(props.activeMonth, 'MMMM', { locale: locales[activeLanguage?.code] })}
              <StyledYear>
                {format(props.activeMonth, 'yyyy', { locale: locales[activeLanguage?.code] })}
              </StyledYear>
            </StyledMonth>
          )}
        </Translate>
        <StyledArrowButton onClick={(_) => props.onSetActiveMonth(addMonths(props.activeMonth, 1))}>
          <FontAwesomeIcon
            icon={faAngleRight}
            style={{
              width: 29,
              height: 29,
            }}
          />
        </StyledArrowButton>
      </StyledHeader>
      <StyledDays>
        <Translate>
          {({ activeLanguage }) =>
            [...new Array(7)].map((_, index) => (
              <StyledWeekday key={index}>
                {format(setDay(props.activeMonth, index + 1), 'ccc', {
                  locale: locales[activeLanguage?.code],
                })}
              </StyledWeekday>
            ))
          }
        </Translate>
        {[...new Array(getISODay(setDate(props.activeMonth, 1)) - 1)].map((_, index) => (
          <StyledDay key={index} />
        ))}
        {[...new Array(getDaysInMonth(props.activeMonth))].map((_, index) => (
          <StyledDay
            key={index}
            onClick={() =>
              !dayIsPast(setDate(props.activeMonth, index + 1), props.pastSelectable) &&
              props.onSetActiveDate(setDate(props.activeMonth, index + 1))
            }
          >
            <StyledDayNumber
              selected={isSameDay(props.activeDate, setDate(props.activeMonth, index + 1))}
              pending={props.pending}
              available={hasAvailableSlots(setDate(props.activeMonth, index + 1), props.slots)}
              past={dayIsPast(setDate(props.activeMonth, index + 1), props.pastSelectable)}
              style={{
                animationDelay: `${index * 30}ms`,
              }}
            >
              {index + 1}
            </StyledDayNumber>
          </StyledDay>
        ))}
      </StyledDays>
    </StyledContainer>
  )
}

export default Calendar
