import { faCircle, faInfinity } from '@fortawesome/pro-light-svg-icons'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import { RootState } from '../../../../state'
import { changeType } from '../../../../state/search/search.actions'
import { typeInfoMapping } from '../../../../utils/index'
import Popup from '../../../general/Popup/Popup'
import RowSelection from '../RowSelection/RowSelection'

const Type = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const selectedType = useSelector((state: RootState) => state.search.terms.type)
  const types = useSelector((state: RootState) => state.general.types.data)
  const getTypeIcon = (typeId: number) => {
    const icon = typeInfoMapping.find((type) => type.id === typeId)?.icon
    return icon ? icon : faCircle
  }

  const onTypeChange = (typeId: number | null) => {
    goBack()
    dispatch(changeType(typeId))
  }

  const goBack = () => {
    history.replace(`/search/appointments${location.search}`)
  }

  return (
    <Popup
      title={<Translate id="type.title" />}
      onClose={typeof selectedType !== 'undefined' ? () => goBack() : undefined}
    >
      <RowSelection
        icon={faInfinity}
        label={<Translate id="type.all" />}
        selected={selectedType === null}
        onClick={() => onTypeChange(null)}
      />
      <Translate>
        {({ activeLanguage }) =>
          types.map((type) => (
            <RowSelection
              key={type.timeslotTypeId}
              icon={getTypeIcon(type.timeslotTypeId)}
              label={type.timeslotTypeName[activeLanguage.code]}
              selected={selectedType === type.timeslotTypeId}
              onClick={() => onTypeChange(type.timeslotTypeId)}
            />
          ))
        }
      </Translate>
    </Popup>
  )
}

export default Type
