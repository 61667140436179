import { createAsyncAction, createAction } from 'typesafe-actions'

import {
  Reservation,
  LocationDetails,
  PersonalInformation,
  ObjectType,
  ObjectDetails,
} from '../../types'

export const iniateLoad = createAction('confirmed/INITIAL_LOAD')<number>()

export const getAppointment = createAsyncAction(
  'confirmed/GET_APPOINTMENT_REQUEST',
  'confirmed/GET_APPOINTMENT_SUCCESS',
  'confirmed/GET_APPOINTMENT_FAILURE'
)<number, Reservation, Error>()

export const cancelAppointment = createAsyncAction(
  'confirmed/CANCEL_APPOINTMENT_REQUEST',
  'confirmed/CANCEL_APPOINTMENT_SUCCESS',
  'confirmed/CANCEL_APPOINTMENT_FAILURE'
)<number, { discussionId: number | null }, Error>()

export const getObjectDetails = createAsyncAction(
  'confirmed/GET_PRACTITIONER_REQUEST',
  'confirmed/GET_PRACTITIONER_SUCCESS',
  'confirmed/GET_PRACTITIONER_FAILURE'
)<{ objectType: ObjectType; objectId: number }, ObjectDetails, Error>()

export const getLocation = createAsyncAction(
  'confirmed/GET_LOCATION_REQUEST',
  'confirmed/GET_LOCATION_SUCCESS',
  'confirmed/GET_LOCATION_FAILURE'
)<{ locationType: ObjectType; locationId: number }, LocationDetails, Error>()

export const resetState = createAction('confirmed/RESET')<void>()

export const getPerson = createAsyncAction(
  'confirmed/GET_PERSON_REQUEST',
  'confirmed/GET_PERSON_SUCCESS',
  'confirmed/GET_PERSON_FAILURE'
)<void, PersonalInformation, Error>()
