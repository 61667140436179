import { parse as parseDate } from 'date-fns'
import { parse as parseQuery } from 'query-string'
import { getType } from 'typesafe-actions'

import {
  SearchTerms,
  GroupedAppointment,
  Practitioner,
  HTTPRequestStatus,
  TimeOfDay,
  Reservation,
} from '../../types'

import { SearchAction, actions } from './'

export interface SearchState {
  terms: SearchTerms
  appointments: {
    data: GroupedAppointment[]
    status: HTTPRequestStatus
    error: Error | null
  }
  reservations: {
    data: Reservation[]
    status: HTTPRequestStatus
    error: Error | null
  }
  alternativeDate: {
    data: Date
    status: HTTPRequestStatus
    error: Error | null
  }
  alternativeAppointments: {
    data: GroupedAppointment[]
    status: HTTPRequestStatus
    error: Error | null
  }
  practitioners: {
    data: Practitioner[]
    status: HTTPRequestStatus
    error: Error | null
  }
}

const initialState: SearchState = {
  terms: {
    date: new Date(),
    timeOfDay: TimeOfDay.All,
    practitioner: null,
    query: null,
    location: [
      {
        name: 'Athens',
        latitude: 37.946512,
        longitude: 23.669842,
      },
    ],
    service: null,
  },
  appointments: {
    data: [],
    status: '',
    error: null,
  },
  reservations: {
    data: [],
    status: '',
    error: null,
  },
  alternativeDate: {
    data: new Date(),
    status: '',
    error: null,
  },
  alternativeAppointments: {
    data: [],
    status: '',
    error: null,
  },
  practitioners: {
    data: [],
    status: '',
    error: null,
  },
}

const getInitialState = () => {
  const query = parseQuery(window.location.search)

  const queryTerms = {
    ...(query.date && { date: parseDate(query.date as string, 'dd-MM-yyyy', new Date()) }),
    ...(query.timeOfDay && { timeOfDay: query.timeOfDay }),
    ...(query.query && { query: query.query }),
    ...(query.service && { service: parseInt(query.service as string, 10) }),
    ...(query.type && { type: query.type === 'all' ? null : parseInt(query.type as string, 10) }),
  }

  return {
    ...initialState,
    terms: {
      ...initialState.terms,
      ...queryTerms,
    },
  }
}

export function reducer(state: SearchState = getInitialState(), action: SearchAction): SearchState {
  switch (action.type) {
    case getType(actions.changeDate): {
      return {
        ...state,
        terms: {
          ...state.terms,
          date: action.payload,
        },
      }
    }

    case getType(actions.changeTimeOfDay): {
      return {
        ...state,
        terms: {
          ...state.terms,
          timeOfDay: action.payload,
        },
      }
    }

    case getType(actions.changeQuery): {
      return {
        ...state,
        terms: {
          ...state.terms,
          query: action.payload,
        },
      }
    }

    case getType(actions.clearQuery): {
      return {
        ...state,
        terms: {
          ...state.terms,
          query: action.payload,
        },
        practitioners: {
          ...state.practitioners,
          data: [],
        },
      }
    }

    case getType(actions.changeService): {
      return {
        ...state,
        terms: {
          ...state.terms,
          service: action.payload,
        },
      }
    }

    case getType(actions.changeLocation): {
      const locationIndex = state.terms.location.findIndex(
        (location) => location === action.payload
      )
      if (locationIndex !== -1) {
        state.terms.location.splice(locationIndex, 1)
      }

      return {
        ...state,
        terms: {
          ...state.terms,
          location: [action.payload, ...state.terms.location],
        },
      }
    }

    case getType(actions.doSearch.request): {
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: 'PENDING',
          data: [],
          error: null,
        },
      }
    }

    case getType(actions.doSearch.success): {
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.doSearch.failure): {
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: 'REJECTED',
          data: [],
          error: action.payload,
        },
      }
    }

    case getType(actions.getNextAvailableAppointment.request): {
      return {
        ...state,
        alternativeDate: {
          ...state.alternativeDate,
          status: 'PENDING',
          data: new Date(),
          error: null,
        },
        alternativeAppointments: {
          ...state.alternativeAppointments,
          data: [],
          error: null,
        },
      }
    }

    case getType(actions.getNextAvailableAppointment.success): {
      return {
        ...state,
        alternativeDate: {
          ...state.alternativeDate,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.getNextAvailableAppointment.failure): {
      return {
        ...state,
        alternativeDate: {
          ...state.alternativeDate,
          status: 'REJECTED',
          data: new Date(),
          error: action.payload,
        },
      }
    }

    case getType(actions.doAlternativeSearch.request): {
      return {
        ...state,
        alternativeAppointments: {
          ...state.alternativeAppointments,
          status: 'PENDING',
          data: [],
          error: null,
        },
      }
    }

    case getType(actions.doAlternativeSearch.success): {
      return {
        ...state,
        alternativeAppointments: {
          ...state.alternativeAppointments,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.doAlternativeSearch.failure): {
      return {
        ...state,
        alternativeAppointments: {
          ...state.alternativeAppointments,
          status: 'REJECTED',
          data: [],
          error: action.payload,
        },
      }
    }

    case getType(actions.doPractitionerSearch.request): {
      return {
        ...state,
        practitioners: {
          ...state.practitioners,
          status: 'PENDING',
          data: [],
          error: null,
        },
      }
    }

    case getType(actions.doPractitionerSearch.success): {
      return {
        ...state,
        practitioners: {
          ...state.practitioners,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.doPractitionerSearch.failure): {
      return {
        ...state,
        practitioners: {
          ...state.practitioners,
          status: 'REJECTED',
          data: [],
          error: action.payload,
        },
      }
    }

    case getType(actions.getReservations.request): {
      const status = action.payload ? state.reservations.status : 'PENDING'
      const data = action.payload ? state.reservations.data : []
      return {
        ...state,
        reservations: {
          ...state.reservations,
          status,
          data,
          error: null,
        },
      }
    }

    case getType(actions.getReservations.success): {
      return {
        ...state,
        reservations: {
          ...state.reservations,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.getReservations.failure): {
      return {
        ...state,
        reservations: {
          ...state.reservations,
          status: 'REJECTED',
          data: [],
          error: action.payload,
        },
      }
    }

    case getType(actions.changeType): {
      return {
        ...state,
        terms: {
          ...state.terms,
          type: action.payload,
        },
      }
    }

    default: {
      return state
    }
  }
}
