import { faCalendarEdit, faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { parseJSON, format } from 'date-fns'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { locales } from '../../../../locale/dateLocales'
import { RootState } from '../../../../state'
import * as actions from '../../../../state/recipient/recipient.actions'
import Loader from '../../../general/Loader/Loader'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 0 5px 5px 5px;
  color: ${(props) => props.theme.invertText};
  background: ${(props) => props.theme.accentColor};
  border-radius: 20px;
`

const StyledTitle = styled.div`
  flex: 1;
`

const StyledCancel = styled.div`
  flex: 0;
  padding-left: 15px;
`

const StyledCancelButton = styled.button`
  color: ${(props) => props.theme.invertText};
`

const Reschedule: React.FC = () => {
  const dispatch = useDispatch()
  const appointmentIdStore = useSelector(
    (state: RootState) => state.recipient.reschedule.appointmentId
  )
  const appointmentDetailsStore = useSelector((state: RootState) => state.recipient.reschedule.data)
  const appointmentDetailsPendingStore = useSelector(
    (state: RootState) => state.recipient.reschedule.status === 'PENDING'
  )

  return appointmentIdStore ? (
    <StyledContainer>
      <StyledTitle>
        {appointmentDetailsPendingStore && appointmentDetailsStore === null ? (
          <Loader size="1x" />
        ) : (
          <>
            <FontAwesomeIcon
              icon={faCalendarEdit}
              style={{
                marginRight: 15,
              }}
            />
            <Translate>
              {({ activeLanguage, translate }) =>
                translate('recipient.rescheduleTitle', {
                  date: format(parseJSON(appointmentDetailsStore!.start), 'Pp', {
                    locale: locales[activeLanguage?.code],
                  }),
                })
              }
            </Translate>
          </>
        )}
      </StyledTitle>
      <StyledCancel>
        <StyledCancelButton onClick={() => dispatch(actions.changeReschedule(null))}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
      </StyledCancel>
    </StyledContainer>
  ) : null
}

export default Reschedule
