import { parseJSON, format } from 'date-fns'
import React, { useEffect } from 'react'
import { LocalizeContextProps, withLocalize } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { locales } from '../../../../locale/dateLocales'
import { RootState } from '../../../../state'
import { getPerson } from '../../../../state/recipient/recipient.actions'
import Loader from '../../../general/Loader/Loader'

const StyledContainer = styled.div`
  padding: 20px;
`

const StyledInput = styled.div`
  margin-bottom: 30px;
  border-bottom: 2px solid ${(props) => props.theme.borderColor};
  padding-bottom: 5px;
`

const StyledTextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  font-family: 'BH Booking Font';
  font-size: 15px;
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 5px;
  resize: none;
`

const StyledLabel = styled.div`
  margin-bottom: 15px;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
`

const StyledLengthIndicator = styled.div`
  color: rgb(127, 127, 127);
  font-size: 11px;
  font-weight: 500;
  height: 13px;
  letter-spacing: 0px;
  text-align: right;
  width: 100%;
`

interface OwnProps {
  additionalInfo: string
  maxLength?: number
  onChangeAdditionalInfo: (value: string) => void
}

type Props = OwnProps & LocalizeContextProps

const PersonalInformation: React.FC<Props> = (props: Props) => {
  const { additionalInfo, maxLength, translate, activeLanguage } = props
  const dispatch = useDispatch()
  const personalInformation = useSelector((state: RootState) => state.recipient.person.data)
  const pending = useSelector(
    (state: RootState) => state.recipient.practitionerDetails.status === 'PENDING'
  )

  useEffect(() => {
    dispatch(getPerson.request())
  }, [dispatch])

  function onChangeAdditionalInfo(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value
    if (!maxLength || value.length <= maxLength) {
      props.onChangeAdditionalInfo(value)
    }
  }

  return pending ? (
    <Loader />
  ) : (
    <StyledContainer>
      <StyledInput>
        <StyledLabel>{translate('personalInformation.nameTitle')}</StyledLabel>
        {personalInformation?.displayName}
      </StyledInput>
      <StyledInput>
        <StyledLabel>{translate('personalInformation.dateOfBirthTitle')}</StyledLabel>
        {personalInformation?.birthDate
          ? format(parseJSON(personalInformation?.birthDate), 'P', {
              locale: locales[activeLanguage?.code],
            })
          : null}
      </StyledInput>
      <StyledInput>
        <StyledLabel>{translate('personalInformation.phoneTitle')}</StyledLabel>
        {personalInformation?.mobilePhone}
      </StyledInput>
      <StyledInput>
        <StyledLabel>{translate('personalInformation.emailTitle')}</StyledLabel>
        {personalInformation?.email}
      </StyledInput>
      <StyledLabel>{translate('recipient.additionalInformationTitle')}</StyledLabel>
      <StyledTextArea
        rows={5}
        value={additionalInfo}
        onChange={onChangeAdditionalInfo}
        placeholder={translate('recipient.additionalInformationPlaceholder') as string}
      />
      {maxLength && (
        <StyledLengthIndicator>
          {additionalInfo.length} / {maxLength}
        </StyledLengthIndicator>
      )}
    </StyledContainer>
  )
}

export default withLocalize(PersonalInformation)
