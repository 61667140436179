import { createAction, createAsyncAction } from 'typesafe-actions'

import {
  SearchTerms,
  GroupedAppointment,
  Practitioner,
  Service,
  Location,
  TimeOfDay,
  Reservation,
  AppointmentType,
} from '../../types'

export const iniateLoad = createAction('search/INITIAL_LOAD')<void>()

export const changeDate = createAction('search/CHANGE_DATE')<Date>()

export const changeTimeOfDay = createAction('search/CHANGE_TIME_OF_DAY')<TimeOfDay>()

export const changeQuery = createAction('search/CHANGE_QUERY')<string | null>()

export const clearQuery = createAction('search/CLEAR_QUERY')<string | null>()

export const changeService = createAction('search/CHANGE_SERVICE')<number | null>()

export const changeLocation = createAction('search/CHANGE_LOCATION')<Location>()

export const changeType = createAction('search/CHANGE_TYPE')<number | null>()

export const doSearch = createAsyncAction(
  'search/DO_SEARCH_REQUEST',
  'search/DO_SEARCH_SUCCESS',
  'search/DO_SEARCH_FAILURE'
)<SearchTerms, GroupedAppointment[], Error>()

export const doPractitionerSearch = createAsyncAction(
  'search/DO_PRACTITIONER_SEARCH_REQUEST',
  'search/DO_PRACTITIONER_SEARCH_SUCCESS',
  'search/DO_PRACTITIONER_SEARCH_FAILURE'
)<string, Practitioner[], Error>()

export const getServices = createAsyncAction(
  'search/GET_SERVICES_REQUEST',
  'search/GET_SERVICES_SUCCESS',
  'search/GET_SERVICES_FAILURE'
)<void, Service[], Error>()

export const getNextAvailableAppointment = createAsyncAction(
  'search/GET_NEXT_APPOINTMENT_REQUEST',
  'search/GET_NEXT_APPOINTMENT_SUCCESS',
  'search/GET_NEXT_APPOINTMENT_FAILURE'
)<Date, Date, Error>()

export const doAlternativeSearch = createAsyncAction(
  'search/DO_ALTERNATIVE_SEARCH_REQUEST',
  'search/DO_ALTERNATIVE_SEARCH_SUCCESS',
  'search/DO_ALTERNATIVE_SEARCH_FAILURE'
)<SearchTerms, GroupedAppointment[], Error>()

export const getReservations = createAsyncAction(
  'search/GET_RESERVATIONS_REQUEST',
  'search/GET_RESERVATIONS_SUCCESS',
  'search/GET_RESERVATIONS_FAILURE'
)<boolean, Reservation[], Error>()

export const getTypes = createAsyncAction(
  'search/GET_TYPES_REQUEST',
  'search/GET_TYPES_SUCCESS',
  'search/GET_TYPES_FAILURE'
)<void, AppointmentType[], Error>()
