import { Subject, BehaviorSubject, Observable, Observer, iif, of } from 'rxjs'
import { switchMap, timeout, tap, filter } from 'rxjs/operators'

import { Coordinates } from '../types'

/* eslint-disable no-console */

declare global {
  interface Window {
    mdhSetUserToken(token: string): void // Deprecated
    mdh: {
      setUserToken(token: string): void
      setUserLocation(lat?: number, long?: number): void
    }
    mdhBridge?: {
      postMessage?(message: string): void
    }
    webkit?: {
      messageHandlers?: {
        mdhBridge?: {
          postMessage?(message: string): void
        }
      }
    }
  }
}

window.mdhSetUserToken = (token: string) => {
  // Deprecated
  console.log('Received token from native bridge')
  tokenSubject.next(token)
}

window.mdh = {
  setUserToken: (token: string) => {
    console.log('Received token from native bridge')
    tokenSubject.next(token)
  },
  setUserLocation: (lat?: number, long?: number) => {
    if (typeof lat !== 'undefined' && typeof long !== 'undefined') {
      console.log('Received location from native bridge')
      locationSubject.next({ lat, long })
    } else {
      locationSubject.error('No location received from native bridge')
    }
  },
}

const tokenSubject = new BehaviorSubject<string | undefined>(
  process.env.REACT_APP_TEST_TOKEN ?? window.sessionStorage.getItem('mdhUserToken') ?? undefined
)
const isNotUndefined = <T>(a: T | undefined): a is T => typeof a !== 'undefined'

export const getUserTokenObservable = tokenSubject.pipe(
  filter(isNotUndefined),
  tap((token) => window.sessionStorage.setItem('mdhUserToken', token))
)

const locationSubject = new Subject<Coordinates>()

export const getUserLocationObservable = new Observable(
  (observer: Observer<Coordinates | void>) => {
    console.log('Requesting location...')
    if (window.mdhBridge?.postMessage) {
      console.log('...from Android')
      window.mdhBridge.postMessage(JSON.stringify({ command: 'getLocation' }))
      observer.next()
    } else if (window.webkit?.messageHandlers?.mdhBridge?.postMessage) {
      console.log('...from iOS')
      window.webkit.messageHandlers.mdhBridge.postMessage(
        JSON.stringify({ command: 'getLocation' })
      )
      observer.next()
    } else {
      console.log('...native bridges not found, falling back to browser')
      navigator.geolocation.getCurrentPosition(
        (position) =>
          observer.next({ lat: position.coords.latitude, long: position.coords.longitude }),
        (error) => observer.error(error)
      )
    }
  }
).pipe(
  timeout(30000),
  switchMap((position) =>
    iif(() => typeof position !== 'undefined', of(position as Coordinates), locationSubject)
  )
)

const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

export const openMap = (latitude: number, longitude: number) =>
  (window.location.href = isIOS
    ? `https://maps.apple.com/?q=${latitude},${longitude}`
    : `geo:${latitude},${longitude}`)

const postMessageToBridge = (message: string) => {
  if (window.mdhBridge?.postMessage) {
    console.log('...on Android')
    window.mdhBridge.postMessage(message)
  } else if (window.webkit?.messageHandlers?.mdhBridge?.postMessage) {
    console.log('...on iOS')
    window.webkit.messageHandlers.mdhBridge.postMessage(message)
  } else {
    console.log('...native bridges not found, dumping to console')
    console.log(message)
  }
}

export const addToCalendar = (event: {
  calendarStartDate: string
  calendarEndDate: string
  calendarEventTitle: string
  calendarEventNotes: string
  calendarEventAddress: string
}) => {
  console.log('Adding an event to calendar...')
  postMessageToBridge(JSON.stringify({ ...event, command: 'addToCalendar' }))
}

export const goToDigitalClinic = () => {
  console.log('Going to Digital Clinic...')
  postMessageToBridge(JSON.stringify({ command: 'goToDigitalClinic' }))
}

export const goToDiscussion = (discussionId: number) => {
  console.log(`Going to discussion ${discussionId}...`)
  postMessageToBridge(JSON.stringify({ command: 'goToDiscussion', discussionId }))
}
