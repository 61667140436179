import { faUserMd, faDiagnoses } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

import { ObjectType } from '../../../types/search'

import style from './ObjectPicture.module.css'

interface Props {
  objectId: number
  objectType: ObjectType
}

const ObjectPicture: React.FC<Props> = ({ objectId, objectType }) => {
  const [noImage, setNoImage] = useState<boolean>(false)

  if (objectType === 'resource') {
    return <FontAwesomeIcon color="#FFFFFF" size="2x" icon={faDiagnoses} />
  }
  return noImage ? (
    <FontAwesomeIcon color="#FFFFFF" size="2x" icon={faUserMd} />
  ) : (
    <img
      onError={(_) => setNoImage(true)}
      className={style.image}
      src={`${process.env.REACT_APP_CUSTOMER_API}/v1/practitioners/${objectId}/photo`}
      alt=""
    />
  )
}

export default ObjectPicture
