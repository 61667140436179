import { Epic, combineEpics } from 'redux-observable'
import { of, from, concat, zip } from 'rxjs'
import { filter, switchMap, map, catchError, take } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { RootAction, RootState } from '../'
import * as api from '../../services/api'

import * as actions from './general.actions'

type GeneralEpic = Epic<RootAction, RootAction, RootState>

const initialLoad: GeneralEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.iniateLoad.request)),
    switchMap(() =>
      concat(
        of(actions.getUser.request()),
        of(actions.getServices.request()),
        of(actions.getTypes.request()),
        zip(
          // Wait for the requests to finish before invoking the rest of the app
          action$.pipe(filter(isActionOf(actions.getUser.success)), take(1)),
          action$.pipe(filter(isActionOf(actions.getServices.success)), take(1)),
          action$.pipe(filter(isActionOf(actions.getTypes.success)), take(1))
        ).pipe(map(() => actions.iniateLoad.success()))
      )
    )
  )

const initialLoadFailure: GeneralEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([actions.getServices.failure, actions.getTypes.failure])),
    take(1),
    switchMap((action) => of(actions.iniateLoad.failure(action.payload)))
  )

const getUser: GeneralEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.getUser.request)),
    switchMap(() =>
      from(api.getUser()).pipe(
        map(actions.getUser.success),
        catchError((error) => of(actions.getUser.failure(error)))
      )
    )
  )

const getServices: GeneralEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.getServices.request)),
    switchMap(() =>
      from(api.getServices()).pipe(
        map(actions.getServices.success),
        catchError((error) => of(actions.getServices.failure(error)))
      )
    )
  )

const getTypes: GeneralEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.getTypes.request)),
    switchMap(() =>
      from(api.getTypes()).pipe(
        map(actions.getTypes.success),
        catchError((error) => of(actions.getTypes.failure(error)))
      )
    )
  )

export const epics = combineEpics(initialLoad, initialLoadFailure, getUser, getServices, getTypes)
