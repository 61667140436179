import {
  GoogleAutocompletePrediction,
  GoogleStatus,
  GoogleGeocoderResult,
  Location,
  Coordinates,
} from '../types'

declare let google: any
const AutocompleteService = new google.maps.places.AutocompleteService()
const Geocoder = new google.maps.Geocoder()

export const autocompleteSearch = (
  input: string,
  location: Location
): Promise<GoogleAutocompletePrediction[]> => {
  return new Promise((resolve, reject) => {
    AutocompleteService.getPlacePredictions(
      {
        input,
        types: ['geocode'],
        location: new google.maps.LatLng(location.latitude, location.longitude),
        radius: 30000 /* prediction bias 30 km */,
      },
      (results: GoogleAutocompletePrediction[], status: GoogleStatus) => {
        switch (status) {
          case 'OK':
            resolve(results)
            break
          case 'ZERO_RESULTS':
            resolve([])
            break
          default:
            reject('Invalid search')
        }
      }
    )
  })
}

export const getPlaceFromId = (name: string, placeId: string): Promise<Location> => {
  return new Promise((resolve, reject) => {
    Geocoder.geocode(
      {
        placeId,
      },
      (results: GoogleGeocoderResult[], status: GoogleStatus) => {
        if (status === 'OK') {
          resolve({
            name,
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          })
        } else {
          reject('No geocoder results')
        }
      }
    )
  })
}

const preferredAddressTypes = [
  'locality',
  'administrative_area_level_1',
  'administrative_area_level_2',
  'administrative_area_level_3',
  'administrative_area_level_4',
  'administrative_area_level_5',
]

const getAppropriateLocationName = (result: GoogleGeocoderResult) => {
  const localityName = result.address_components.find((component) =>
    component.types.some((type) => preferredAddressTypes.includes(type))
  )
  if (localityName) {
    return localityName.long_name
  } else {
    return result.formatted_address
  }
}

export const getPlaceFromCoordinates = (coords: Coordinates): Promise<Location> => {
  return new Promise((resolve, reject) => {
    Geocoder.geocode(
      {
        location: new google.maps.LatLng(coords.lat, coords.long),
      },
      (results: GoogleGeocoderResult[], status: GoogleStatus) => {
        if (status === 'OK') {
          resolve({
            name: getAppropriateLocationName(results[0]),
            latitude: coords.lat,
            longitude: coords.long,
          })
        } else {
          reject('No geocoder results')
        }
      }
    )
  })
}
