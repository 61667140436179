import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

const StyledHeader = styled.div`
  background: ${(props) => props.theme.accentColor};
  padding: 40px 0 20px 0;
  padding: calc(20px + max(20px, env(safe-area-inset-top))) 0 20px 0;
  text-align: center;
`

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledBack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  color: ${(props) => props.theme.invertText};
`

const StyledPlaceholder = styled.div`
  width: 44px;
  height: 44px;
`

const StyledTitle = styled.h1`
  flex: 1;
  color: ${(props) => props.theme.invertText};
  font-size: 19px;
  font-weight: 600;
  margin: 0;
`

interface Props {
  title: JSX.Element
  showBack: boolean
  goBack: () => void
}

const Header: React.FC<Props> = ({ title, showBack, goBack }) => {
  return (
    <StyledHeader>
      <StyledContainer>
        {showBack ? (
          <StyledBack onClick={goBack}>
            <FontAwesomeIcon
              icon={faCaretLeft}
              style={{
                width: 24,
                height: 24,
              }}
            />
          </StyledBack>
        ) : (
          <StyledPlaceholder />
        )}
        <StyledTitle>{title}</StyledTitle>
        <StyledPlaceholder />
      </StyledContainer>
    </StyledHeader>
  )
}

export default Header
