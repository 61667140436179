import { Epic, combineEpics } from 'redux-observable'
import { of, from, EMPTY } from 'rxjs'
import { filter, switchMap, map, catchError, mergeMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { RootAction, RootState } from '../'
import * as api from '../../services/api'
import * as native from '../../services/native'
import { Firebase, assertNever, assertNeverWith } from '../../utils'
import { getUserId } from '../general/general.selectors'

import * as actions from './confirmed.actions'

type ConfirmedEpic = Epic<RootAction, RootAction, RootState>

const initialLoad: ConfirmedEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.iniateLoad)),
    tap(() => Firebase.trackEvent('screen_view', { screen_name: 'appointment' })),
    switchMap((action) =>
      of(actions.getAppointment.request(action.payload), actions.getPerson.request())
    )
  )

const getAppointment: ConfirmedEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getAppointment.request)),
    switchMap((action) =>
      from(api.getAppointment(getUserId(state$.value.general), action.payload)).pipe(
        map(actions.getAppointment.success),
        catchError((error) => of(actions.getAppointment.failure(error)))
      )
    )
  )

const getObjectDetailsTrigger: ConfirmedEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getAppointment.success)),
    mergeMap(() => {
      const objectType = state$.value.confirmed.appointment.data?.objectType
      const objectId = state$.value.confirmed.appointment.data?.objectId
      if (objectType === undefined || objectId === undefined) return EMPTY
      return of(actions.getObjectDetails.request({ objectType, objectId }))
    })
  )

const getObjectDetails: ConfirmedEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getObjectDetails.request)),
    switchMap((action) =>
      action.payload.objectType === 'practitioner'
        ? from(
            api.getPractitionerDetails(getUserId(state$.value.general), action.payload.objectId)
          ).pipe(
            map((practitioner) =>
              actions.getObjectDetails.success({ kind: 'PractitionerDetails', ...practitioner })
            ),
            catchError((error) => of(actions.getObjectDetails.failure(error)))
          )
        : action.payload.objectType === 'resource'
        ? from(
            api.getResourceDetails(getUserId(state$.value.general), action.payload.objectId)
          ).pipe(
            map((resource) =>
              actions.getObjectDetails.success({ kind: 'ResourceDetails', ...resource })
            ),
            catchError((error) => of(actions.getObjectDetails.failure(error)))
          )
        : assertNeverWith(action.payload.objectType, EMPTY)
    )
  )

const getLocationTrigger: ConfirmedEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getObjectDetails.success)),
    mergeMap(() => {
      if (!state$.value.confirmed.objectDetails.data || !state$.value.confirmed.appointment.data) {
        return EMPTY
      }
      const locationId = state$.value.confirmed.appointment.data.location.locationId
      const objectKind = state$.value.confirmed.objectDetails.data.kind
      return of(
        actions.getLocation.request({
          locationType:
            objectKind === 'PractitionerDetails'
              ? ('practitioner' as const)
              : objectKind === 'ResourceDetails'
              ? ('resource' as const)
              : assertNever(objectKind),
          locationId,
        })
      )
    })
  )

const getLocation: ConfirmedEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getLocation.request)),
    switchMap((action) => {
      return from(
        api.getLocation(
          getUserId(state$.value.general),
          action.payload.locationType,
          action.payload.locationId
        )
      ).pipe(
        map(actions.getLocation.success),
        catchError((error) => of(actions.getLocation.failure(error)))
      )
    })
  )

const cancelAppointment: ConfirmedEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.cancelAppointment.request)),
    switchMap((action) =>
      from(api.cancel(getUserId(state$.value.general), action.payload)).pipe(
        tap(() => Firebase.trackEvent('refund', { transaction_id: action.payload })),
        tap(({ discussionId }) => {
          if (discussionId !== null) native.goToDiscussion(discussionId)
        }),
        map(actions.cancelAppointment.success),
        catchError((error) => of(actions.cancelAppointment.failure(error)))
      )
    )
  )

const getPerson: ConfirmedEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getPerson.request)),
    switchMap(() =>
      from(api.getPerson(getUserId(state$.value.general))).pipe(
        map(actions.getPerson.success),
        catchError((error) => of(actions.getPerson.failure(error)))
      )
    )
  )

export const epics = combineEpics(
  initialLoad,
  getAppointment,
  getObjectDetailsTrigger,
  getObjectDetails,
  getLocationTrigger,
  getLocation,
  cancelAppointment,
  getPerson
)
