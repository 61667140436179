import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import React from 'react'
import { LocalizeProvider } from 'react-localize-redux'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import configureStore from '../../store'
import { ThemeGate } from '../../themes'
import Loader from '../general/Loader/Loader'
import { TokenGate } from '../general/TokenGate/TokenGate'

import RootComponent from './RootComponent'

const App: React.FC = () => {
  const history = createBrowserHistory()
  const { store, persistor } = configureStore(history)

  return (
    <TokenGate>
      <ReduxProvider store={store}>
        <LocalizeProvider>
          <ConnectedRouter history={history}>
            <PersistGate loading={<Loader />} persistor={persistor}>
              <ThemeGate>
                <RootComponent />
              </ThemeGate>
            </PersistGate>
          </ConnectedRouter>
        </LocalizeProvider>
      </ReduxProvider>
    </TokenGate>
  )
}

export default App
