import { push } from 'connected-react-router'
import { format } from 'date-fns'
import { stringify } from 'query-string'
import { Epic, combineEpics } from 'redux-observable'
import { of, from, iif, concat } from 'rxjs'
import { filter, mergeMap, switchMap, map, catchError, debounceTime, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { RootAction, RootState } from '../'
import * as api from '../../services/api'
import { Firebase } from '../../utils'
import { getUserId } from '../general/general.selectors'

import * as actions from './search.actions'
import { SearchState } from './search.reducer'

type SearchEpic = Epic<RootAction, RootAction, RootState>

const searchTermActions = [
  actions.changeDate,
  actions.changeTimeOfDay,
  actions.changeService,
  actions.changeLocation,
  actions.changeType,
]

const urlQueryActions = [
  actions.changeQuery,
  actions.changeDate,
  actions.changeTimeOfDay,
  actions.changeService,
  actions.changeLocation,
  actions.changeType,
]

const initialLoad: SearchEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.iniateLoad)),
    tap(() => Firebase.trackEvent('screen_view', { screen_name: 'search' })),
    mergeMap(() =>
      concat(
        of(actions.getReservations.request(false)),
        iif(
          () => typeof state$.value.search.terms.type === 'undefined',
          of(push(`/search/type?${state$.value.router.location.search}`)),
          of(actions.doSearch.request(state$.value.search.terms))
        )
      )
    )
  )

const updateSearchQuery: SearchEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(urlQueryActions)),
    mergeMap((_) =>
      of(
        push(`${state$.value.router.location.pathname}?${generateTermsQuery(state$.value.search)}`)
      )
    )
  )

const updateSerch: SearchEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(searchTermActions)),
    mergeMap((_) => of(actions.doSearch.request(state$.value.search.terms)))
  )

const doSearch: SearchEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.doSearch.request)),
    tap((action) => Firebase.trackEvent('search', { search_term: action.payload })),
    switchMap((action) =>
      from(api.searchGrouped(action.payload)).pipe(
        map(actions.doSearch.success),
        catchError((error) => of(actions.doSearch.failure(error)))
      )
    )
  )

const updatePractitionerSearch: SearchEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.changeQuery)),
    mergeMap((action) =>
      iif(
        () => action.payload === null || action.payload.length <= 3,
        of(actions.clearQuery(action.payload)),
        of(actions.doPractitionerSearch.request(action.payload as string))
      )
    )
  )

const doPractitionerSearch: SearchEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.doPractitionerSearch.request)),
    debounceTime(500),
    switchMap((action) =>
      from(api.practitionerSearch(action.payload)).pipe(
        map(actions.doPractitionerSearch.success),
        catchError((error) => of(actions.doPractitionerSearch.failure(error)))
      )
    )
  )

const alternativeDateTrigger: SearchEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.doSearch.success)),
    filter((action) => action.payload.length === 0),
    mergeMap((_) => of(actions.getNextAvailableAppointment.request(state$.value.search.terms.date)))
  )

const getNextAvailableAppointment: SearchEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getNextAvailableAppointment.request)),
    switchMap((_) =>
      from(api.getNextAvailableAppointment(state$.value.search.terms)).pipe(
        map(actions.getNextAvailableAppointment.success),
        catchError((error) => of(actions.getNextAvailableAppointment.failure(error)))
      )
    )
  )

const alternativeSearchTrigger: SearchEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getNextAvailableAppointment.success)),
    mergeMap((action) =>
      of(
        actions.doAlternativeSearch.request({ ...state$.value.search.terms, date: action.payload })
      )
    )
  )

const doAlternativeSearch: SearchEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.doAlternativeSearch.request)),
    switchMap((action) =>
      from(api.searchGrouped(action.payload)).pipe(
        map(actions.doAlternativeSearch.success),
        catchError((error) => of(actions.doAlternativeSearch.failure(error)))
      )
    )
  )

const getReservations: SearchEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getReservations.request)),
    switchMap((_) =>
      from(api.getReservations(getUserId(state$.value.general))).pipe(
        map(actions.getReservations.success),
        catchError((error) => of(actions.getReservations.failure(error)))
      )
    )
  )

export const epics = combineEpics(
  initialLoad,
  updateSearchQuery,
  updateSerch,
  doSearch,
  alternativeDateTrigger,
  getNextAvailableAppointment,
  alternativeSearchTrigger,
  doAlternativeSearch,
  updatePractitionerSearch,
  doPractitionerSearch,
  getReservations
)

const generateTermsQuery = (state: SearchState) => {
  const url = {
    date: format(state.terms.date, 'dd-MM-yyyy'),
    timeOfDay: state.terms.timeOfDay,
    ...(state.terms.query !== null && { query: state.terms.query }),
    ...(state.terms.service !== null && { service: state.terms.service }),
    ...(typeof state.terms.type !== 'undefined' && {
      type: state.terms.type === null ? 'all' : state.terms.type,
    }),
  }

  return stringify(url)
}
