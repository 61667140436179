import cn from 'classnames'
import React from 'react'

import Style from './Loader.module.css'

interface Props {
  size?: '1x' | '2x' | '3x'
  color?: string
}

const Loader: React.FC<Props> = (props) => {
  return (
    <div
      className={cn(
        Style.loader,
        { [Style.loader1x]: props.size === '1x' },
        { [Style.loader2x]: props.size === '2x' },
        { [Style.loader3x]: typeof props.size === 'undefined' || props.size === '3x' }
      )}
      style={{
        borderLeftColor: props.color ? props.color : 'white',
      }}
    >
      Loading...
    </div>
  )
}

export default Loader
