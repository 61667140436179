import { faAngleDown, faAngleUp, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { darken } from 'polished'
import React, { useState } from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  padding: 15px 20px;
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`

const StyledTitleContainer = styled.div`
  flex: 1;
  overflow: hidden;
`

const StyledIcon = styled.div<{ margin: 'left' | 'right' }>`
  display: flex;
  margin-left: ${(props) => (props.margin === 'left' ? '10px' : 0)};
  margin-right: ${(props) => (props.margin === 'right' ? '10px' : 0)};
  color: ${(props) => darken(0.1, props.theme.accentColor)};
`

const StyledTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.primaryText};
`

const StyledContent = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  padding-top: 15px;
`

interface Props {
  title: string | JSX.Element
  className?: string
}

const Accordion: React.FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <StyledContainer className={props.className}>
      <StyledHeader onClick={() => setOpen(!open)}>
        <StyledIcon margin={'right'}>
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{
              width: 23,
              height: 23,
            }}
          />
        </StyledIcon>
        <StyledTitleContainer>
          <StyledTitle>{props.title}</StyledTitle>
        </StyledTitleContainer>
        <StyledIcon margin={'left'}>
          <FontAwesomeIcon
            icon={open ? faAngleUp : faAngleDown}
            style={{
              width: 29,
              height: 29,
            }}
          />
        </StyledIcon>
      </StyledHeader>
      <StyledContent open={open}>{props.children}</StyledContent>
    </StyledContainer>
  )
}

export default Accordion
