import React from 'react'
import { Translate } from 'react-localize-redux'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { RootState } from '../../../../state'
import { changeService } from '../../../../state/search/search.actions'
import Popup from '../../../general/Popup/Popup'
import RowSelection from '../RowSelection/RowSelection'

const Services: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch()
  const services = useSelector((state: RootState) => state.general.services.data)
  const selectedService = useSelector((state: RootState) => state.search.terms.service)

  const onServiceChange = (serviceId: number | null) => {
    goBack()
    dispatch(changeService(serviceId))
  }

  const goBack = () => {
    props.history.replace(`/search/appointments${props.location.search}`)
  }

  return (
    <Popup title={<Translate id="services.title" />} onClose={() => goBack()}>
      <RowSelection
        label={<Translate id="services.all" />}
        onClick={() => onServiceChange(null)}
        selected={selectedService === null}
      />
      <Translate>
        {({ activeLanguage }) =>
          services.map((service) => (
            <RowSelection
              key={service.medicalServiceId}
              label={service.medicalServiceName[activeLanguage.code]}
              onClick={() => onServiceChange(service.medicalServiceId)}
              selected={selectedService === service.medicalServiceId}
            />
          ))
        }
      </Translate>
    </Popup>
  )
}

export default withRouter(Services)
