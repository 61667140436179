import { getType } from 'typesafe-actions'

import { Service, HTTPRequestStatus, AppointmentType, User } from '../../types'

import { GeneralAction, actions } from './'

export interface GeneralState {
  ready: boolean
  error: Error | null
  user: {
    data: User | null
    status: HTTPRequestStatus
    error: Error | null
  }
  services: {
    data: Service[]
    status: HTTPRequestStatus
    error: Error | null
  }
  types: {
    data: AppointmentType[]
    status: HTTPRequestStatus
    error: Error | null
  }
}

const initialState: GeneralState = {
  ready: false,
  error: null,
  user: {
    data: null,
    status: '',
    error: null,
  },
  services: {
    data: [],
    status: '',
    error: null,
  },
  types: {
    data: [],
    status: '',
    error: null,
  },
}

export function reducer(state: GeneralState = initialState, action: GeneralAction): GeneralState {
  switch (action.type) {
    case getType(actions.iniateLoad.success): {
      return {
        ...state,
        ready: true,
      }
    }

    case getType(actions.iniateLoad.failure): {
      return {
        ...state,
        ready: false,
        error: action.payload,
      }
    }

    case getType(actions.getServices.request): {
      return {
        ...state,
        services: {
          ...state.services,
          status: 'PENDING',
          data: [],
          error: null,
        },
      }
    }

    case getType(actions.getUser.success): {
      return {
        ...state,
        user: {
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.getUser.failure): {
      return {
        ...state,
        user: {
          status: 'REJECTED',
          data: null,
          error: action.payload,
        },
      }
    }

    case getType(actions.getUser.request): {
      return {
        ...state,
        user: {
          status: 'PENDING',
          data: null,
          error: null,
        },
      }
    }

    case getType(actions.getServices.success): {
      return {
        ...state,
        services: {
          ...state.services,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.getServices.failure): {
      return {
        ...state,
        services: {
          ...state.services,
          status: 'REJECTED',
          data: [],
          error: action.payload,
        },
      }
    }

    case getType(actions.getTypes.request): {
      return {
        ...state,
        types: {
          ...state.types,
          status: 'PENDING',
          data: [],
          error: null,
        },
      }
    }

    case getType(actions.getTypes.success): {
      return {
        ...state,
        types: {
          ...state.types,
          status: 'FULFILLED',
          data: action.payload,
          error: null,
        },
      }
    }

    case getType(actions.getTypes.failure): {
      return {
        ...state,
        types: {
          ...state.types,
          status: 'REJECTED',
          data: [],
          error: action.payload,
        },
      }
    }

    default: {
      return state
    }
  }
}
